import React, {Component} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';

import {appendFormData} from '../../../../utils/commonFunctions';

class AddExportList extends Component {
  state = {
    loading: this.props.loading,
    clients: this.props.clients,
    data: {},
    errors: {},
    validated: false,
  };

  UNSAFE_componentWillMount = () => {
    const {cloneData} = this.props;

    if (cloneData && Object.entries(cloneData).length > 0) {
      this.setState({
        data: {
          list_name: `${cloneData.list_name} clone`,
          file_name: `${cloneData.file_name}_clone`,
          kunde: `${cloneData.kunde}`,
          schedule_time: `${cloneData.schedule_time}`,
          schedule_hours: `${cloneData.schedule_hours}`,
          use_external_server: cloneData.use_external_server,
          ftp_file_path: `${cloneData.ftp_file_path}`,
          ftp_host: `${cloneData.ftp_host}`,
          ftp_password: `${cloneData.ftp_password}`,
          ftp_user_name: `${cloneData.ftp_user_name}`,
          columns: cloneData.columns,
        },
      });
    }
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {loading, clients} = props;

    this.setState({
      loading,
      clients,
    });
  };

  onChange = e => {
    let data = {};

    // console.log('e', e.target.type);

    data = {
      ...this.state.data,
      [e.target.name]:
        e.target.type !== 'checkbox' ? e.target.value : e.target.checked,
    };

    this.setState({
      data,
    });
  };

  validateData = () => {
    const errors = {};
    const {data} = this.state;

    if (!data.list_name || data.list_name === '') {
      errors.list_name = 'Please enter List Title.';
    }
    if (!data.file_name || data.file_name === '') {
      errors.file_name = 'Please enter File name.';
    }
    if (!data.kunde || data.kunde === '') {
      errors.kunde = 'Please select Client.';
    }
    if (!data.schedule_time || data.schedule_time === '') {
      errors.schedule_time = 'Please Choose Time.';
    }

    if (data.use_external_server) {
      if (!data.ftp_type || data.ftp_type === '') {
        errors.ftp_type = 'Please select FTP Type.';
      }

      if (!data.ftp_host || data.ftp_host === '') {
        errors.ftp_host = 'Please Enter FTP Host name.';
      }
      if (!data.ftp_user_name || data.ftp_user_name === '') {
        errors.ftp_user_name = 'Please Enter FTP User name.';
      }
      if (!data.ftp_password || data.ftp_password === '') {
        errors.ftp_password = 'Please Enter FTP Password.';
      }
      if (!data.ftp_file_path || data.ftp_file_path === '') {
        errors.ftp_file_path = 'Please Enter FTP File Path.';
      }
    }

    return errors;
  };

  onSubmit = () => {
    const {data, editColumnIndex} = this.state;
    const errors = this.validateData();

    if (!Object.entries(errors).length) {
      const formData = appendFormData(data);

      if (data.columns) {
        formData.append('columns', JSON.stringify(data.columns));
      }

      this.props.saveList(formData);
    } else {
      this.setState({
        validated: true,
        errors,
      });
    }
  };

  render() {
    const {validated, loading, data, errors, clients} = this.state;
    return (
      <Modal
        className="medium"
        show={this.props.showModal}
        onHide={() => this.props.toggleModal('add_list_modal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} onSubmit={() => this.onSubmit()}>
            <Form.Group>
              <Form.Control
                type="text"
                name="list_name"
                value={data.list_name}
                required
                placeholder="List Name"
                onChange={e => this.onChange(e)}
              />
              {errors.list_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.list_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="file_name"
                value={data.file_name}
                required
                placeholder="CSV File Name"
                onChange={e => this.onChange(e)}
              />
              {errors.file_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.file_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                id="kunde"
                name="kunde"
                as="select"
                required
                value={data.kunde}
                onChange={e => this.onChange(e)}>
                <option value="">Choose Client</option>
                {Object.entries(clients).length != 0 &&
                  clients.map(item => (
                    <option key={`client-${item}`} value={item}>
                      {item}
                    </option>
                  ))}
              </Form.Control>

              {errors.kunde && (
                <Form.Control.Feedback type="invalid">
                  {errors.kunde}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                id="schedule_time"
                name="schedule_time"
                as="select"
                required
                value={data.schedule_time}
                onChange={e => this.onChange(e)}>
                <option value="">Choose Schedule</option>
                <option value="hourly">Every Hour</option>
                <option value="2-hourly">Every Two Hour</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </Form.Control>
              {errors.schedule_time && (
                <Form.Control.Feedback type="invalid">
                  {errors.schedule_time}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {(data.schedule_time === 'daily' ||
              data.schedule_time === 'weekly') && (
              <Form.Group>
                <Form.Control
                  type="time"
                  name="schedule_hours"
                  value={data.schedule_hours}
                  required
                  onChange={e => this.onChange(e)}
                />
                {errors.file_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.file_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            <Form.Group>
              <Form.Check
                custom
                type="switch"
                id="use_external_server"
                name="use_external_server"
                label="External Server"
                checked={data.use_external_server ? 'checked' : ''}
                onChange={e => {
                  this.onChange(e);
                }}
              />
            </Form.Group>

            {data.use_external_server && (
              <>
                <Row className="mb-3">
                  <Col md="9">
                    <Form.Group>
                      <Form.Control
                        id="ftp_type"
                        name="ftp_type"
                        as="select"
                        required
                        onChange={e => this.onChange(e)}
                        value={data.ftp_type}>
                        <option value="">Choose Type</option>
                        <option value="ftp">
                          FTP - File Transafer Protocol
                        </option>
                        <option value="sftp">
                          SFTP - SSH File Transafer Protocol
                        </option>
                      </Form.Control>

                      {errors.ftp_type && (
                        <Form.Control.Feedback type="invalid">
                          {errors.ftp_type}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        name="ftp_port"
                        value={data.ftp_port}
                        placeholder="FTP Port"
                        onChange={e => this.onChange(e)}
                      />
                      {errors.ftp_port && (
                        <Form.Control.Feedback type="invalid">
                          {errors.ftp_port}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Control
                    type="text"
                    name="ftp_host"
                    value={data.ftp_host}
                    required
                    placeholder="FTP Host"
                    onChange={e => this.onChange(e)}
                  />
                  {errors.ftp_host && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ftp_host}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="ftp_user_name"
                    value={data.ftp_user_name}
                    required
                    placeholder="FTP User Name"
                    onChange={e => this.onChange(e)}
                  />
                  {errors.ftp_user_name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ftp_user_name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="ftp_password"
                    value={data.ftp_password}
                    required
                    placeholder="FTP Password"
                    onChange={e => this.onChange(e)}
                  />
                  {errors.ftp_password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ftp_password}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="ftp_file_path"
                    value={data.ftp_file_path}
                    required
                    placeholder="FTP File Path"
                    onChange={e => this.onChange(e)}
                  />
                  {errors.ftp_file_path && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ftp_file_path}
                    </Form.Control.Feedback>
                  )}
                  <span
                    className="info-text mb-0 mt-3"
                    style={{
                      color: '#999',
                      lineHeight: '1.4em',
                    }}>
                    * Please make sure the path and directory exists in the
                    remote FTP / SFTP to upload files.
                  </span>
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn solid btn-medium"
            onClick={() => this.onSubmit()}>
            {!loading && <span>Save</span>}
            {loading && (
              <span>
                <i className="fas fa-spinner fa-pulse loadingIcon" />
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddExportList;
