import React, { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";

class EditColumnModal extends Component {
    state = {
        validated: false,
        loading: this.props.loading,
        fields: this.props.fields,
        data: this.props.column,
        editColumnIndex: this.props.editColumnIndex,
        errors: {},
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        const { loading } = props;

        this.setState({
            loading,
        });
    };

    onChange = (e) => {
        let data = {};
        if (e.target.name === "field_value" && e.target.value !== "Custom") {
            data = {
                ...this.state.data,
                [e.target.name]: e.target.value,
                custom_value: "",
            };
        } else {
            data = {
                ...this.state.data,
                [e.target.name]: e.target.value,
            };
        }

        this.setState({
            data,
        });
    };

    validateData = () => {
        const errors = {};
        const { data } = this.state;

        if (!data.title || data.title === "") {
            errors.title = "Please enter Column Title";
        }

        if (!data.field_value || data.field_value === "") {
            errors.field_value = "Please select Column value";
        }

        if (data.field_value && data.field_value === "Custom" && (!data.custom_value || data.custom_value === "")) {
            errors.custom_value = "Please enter Custom value";
        }

        return errors;
    };

    onSubmit = () => {
        const { data, editColumnIndex } = this.state;
        const errors = this.validateData();
        if (!Object.entries(errors).length) {
            this.props.updateColumn(data, editColumnIndex);
        } else {
            this.setState({
                validated: true,
                errors,
            });
        }
    };

    render() {
        const { validated, loading, data, errors, fields } = this.state;

        return (
            <Modal
                className="medium"
                show={this.props.showModal}
                onHide={() => this.props.toggleModal("edit_column_modal", false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Column!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form validated={validated} onSubmit={this.onSubmit}>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="title"
                                value={data.title}
                                required
                                placeholder="Column Title"
                                onChange={(e) => this.onChange(e)}
                            />
                            {errors.title && (
                                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                id="field_value"
                                name="field_value"
                                as="select"
                                required
                                onChange={(e) => this.onChange(e)}
                            >
                                <option value="">Choose Column Value</option>
                                <option selected={data.field_value == "Custom" ? "selected" : ""} value="Custom">
                                    Custom
                                </option>
                                {Object.entries(fields).length &&
                                    fields.map((field, index) => (
                                        <option
                                            selected={data.field_value == field ? "selected" : ""}
                                            key={`fields_key_${index}`}
                                            value={field}
                                        >
                                            {field}
                                        </option>
                                    ))}
                            </Form.Control>
                            {errors.field_value && (
                                <Form.Control.Feedback type="invalid">{errors.field_value}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        {data.field_value === "Custom" && (
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="custom_value"
                                    value={data.custom_value}
                                    required
                                    placeholder="Custom Value"
                                    onChange={(e) => this.onChange(e)}
                                />
                                {errors.custom_value && (
                                    <Form.Control.Feedback type="invalid">{errors.custom_value}</Form.Control.Feedback>
                                )}
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn solid btn-medium" onClick={() => this.onSubmit()}>
                        {!loading && <span>Save</span>}
                        {loading && (
                            <span>
                                <i className="fas fa-spinner fa-pulse loadingIcon" />
                            </span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default EditColumnModal;
