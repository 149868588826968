import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Card} from 'react-bootstrap';
import AlertDismissible from '../../../Elements/AlertDismissible';

import {
  addNewExportList,
  updateExportList,
  deleteExportList,
  getExportLists,
} from '../../../../Actions/ExportActions';

import API from '../../../../utils/api';
import {base_url} from '../../../../utils/env';

import AddExportList from '../Modals/AddExportList';
import EditExportList from '../Modals/EditExportList';
import {connect} from 'react-redux';

class ExportList extends Component {
  state = {
    loading: false,
    exporting_all: false,
    export_list: {},
    copied_url_id: '',
    add_list_modal: false,
    edit_list_modal: false,
    edit_list_data: {},
    page: 1,
    pagginInfo: {},
    clients: [],
    exportLoding: {},
    cloneListData: {},
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    // const { page } = this.state;

    const url = window.location.href;
    const pageParams = url.split('/page/');
    const page = pageParams[1] ? pageParams[1] : '1';

    this.get_exportList(page);
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {export_list} = props;

    this.setState({
      export_list,
    });
  };

  get_exportList = (page, serach = '') => {
    this.setState({loading: true});
    this.props
      .getExportLists(page)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            loading: false,
            pagginInfo: res.pagging_info,
            currentIndex: res.pagging_info.indexStart,
            clients: res.clients,
            data: {
              ...this.state.data,
              searchLoading: false,
            },
          });
        } else if (res.type === 'error') {
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  saveList = listData => {
    this.setState({
      loading: true,
    });

    this.props
      .addNewExportList(listData)
      .then(res => {
        this.setState({
          loading: false,
          add_list_modal: false,
          alertData: {
            heading: '',
            message: res.message,
            show: true,
            variant: 'success',
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          add_list_modal: false,
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  updateList = listData => {
    this.setState({
      loading: true,
    });
    this.props
      .updateExportList(listData)
      .then(res => {
        this.setState({
          loading: false,
          edit_list_modal: false,
          alertData: {
            heading: '',
            message: res.message,
            show: true,
            variant: res.type == 'error' ? 'danger' : res.type,
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          edit_list_modal: false,
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  removeList = id => {
    this.setState({
      loading: true,
    });

    const formData = new FormData();
    formData.append('id', id);

    this.props
      .deleteExportList(formData)
      .then(res => {
        this.setState({
          loading: false,
          alertData: {
            heading: '',
            message: res.message,
            show: true,
            variant: 'success',
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  toggleModal = (modal, state) => {
    this.setState({
      ...this.state,
      [modal]: state,
    });
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        show: false,
      },
    });
  };

  copy_csv_url = list => {
    const urlPath = `${base_url}/export/${list.file_name}.csv`;
    // console.log('URL', urlPath);

    this.setState({
      copied_url_id: list.id,
      //[`copied_${list.id}_url`]: true,
    });

    const textField = document.createElement('textarea');
    textField.innerText = urlPath;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  exportList = (listId, index) => {
    const tempExportLoading = this.state.exportLoding;

    if (!tempExportLoading[listId]) {
      this.setState({
        exportLoding: {
          ...this.state.exportLoding,
          [listId]: true,
        },
      });

      API.admin
        .exportList(listId)
        .then(res => {
          this.setState({
            exportLoding: {
              ...this.state.exportLoding,
              [listId]: false,
            },
            alertData: {
              heading: '',
              message: res.message,
              show: true,
              variant: res.type,
            },
          });
        })
        .catch(err => {
          this.setState({
            exportLoding: {
              ...this.state.exportLoding,
              [listId]: false,
            },
            alertData: {
              heading: '',
              message: err.message,
              show: true,
              variant: 'danger',
            },
          });
        });
    }
  };

  cloneItem = listItem => {
    //

    this.setState({
      add_list_modal: true,
      cloneListData: listItem,
    });
  };

  _render_list_item = (list, index, startIndex) => {
    startIndex = startIndex + index;
    const {copied_url_id, exportLoding} = this.state;

    return (
      <tr key={`export_list_item_${index}`}>
        <td>{startIndex}</td>
        <td>{list.list_name}</td>
        <td>{list.file_name}</td>
        <td>{list.kunde ? list.kunde : ' - '}</td>
        <td>{list.schedule_time}</td>
        <td>{list.next_run}</td>
        <td className="text-center">
          <Button
            className="btn solid"
            title="Copy URL"
            onClick={() => this.copy_csv_url(list)}>
            {copied_url_id !== list.id && <span>Copy</span>}
            {copied_url_id === list.id && <span>Copied!</span>}
          </Button>
        </td>
        <td>
          <Button
            type="button"
            className="btn solid"
            onClick={() => this.exportList(list.id, index)}
            title={`Export ${list.list_name}`}>
            {!exportLoding[list.id] && <span>Export</span>}
            {exportLoding[list.id] && (
              <span>
                <i className="fas fa-spinner fa-spin" />
              </span>
            )}
          </Button>
        </td>
        <td className="text-center">
          <Link to={`export-lists/manage-columns/${list.id}`}>
            <Button
              className="btn solid mr-2 btn-rounded"
              title="Manage Columns">
              <i className="fas fa-columns" />
            </Button>
          </Link>

          <Button
            onClick={() => this.cloneItem(list)}
            className="btn solid btn-rounded"
            title="Clone List Item">
            <i className="fas fa-clone" />
          </Button>

          <Button
            onClick={() => {
              this.setState({
                edit_list_data: list,
                edit_list_modal: true,
              });
            }}
            className="btn solid mr-2 btn-rounded"
            title="Edit List">
            <i className="fas fa-edit" />
          </Button>

          <Button
            onClick={() => this.removeList(list.id)}
            className="btn solid btn-danger btn-rounded"
            title="Remove List">
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    );
  };

  _render_pagging = () => {
    const {pagginInfo, data} = this.state;

    const nextClass = pagginInfo.next === 'disable' ? 'disabled' : '';
    const prvClass = pagginInfo.previous === 'disable' ? 'disabled' : '';

    return (
      <div className="pagging-info">
        {pagginInfo.total_item} items
        <span
          className={`nav-btn prv ${prvClass}`}
          onClick={() => {
            if (pagginInfo.previous !== 'disable')
              this.get_exportList(pagginInfo.previous, data.search);
          }}>
          ‹
        </span>
        <span className="page-text">
          Current Page {pagginInfo.current} of {pagginInfo.total_pages}
        </span>
        <span
          className={`nav-btn next ${nextClass}`}
          onClick={() => {
            if (pagginInfo.next !== 'disable')
              this.get_exportList(pagginInfo.next, data.search);
          }}>
          ›
        </span>
      </div>
    );
  };

  exportAll = () => {
    const {exporting_all} = this.state;
    if (!exporting_all) {
      this.setState(
        {
          exporting_all: true,
        },
        () => {
          API.admin
            .exportAllCsv()
            .then(res => {
              this.setState({
                exporting_all: false,
                alertData: {
                  heading: '',
                  message: res.message,
                  show: true,
                  variant: res.error ? 'danger' : res.type,
                },
              });
            })
            .catch(err => {
              this.setState({
                exporting_all: false,
                alertData: {
                  heading: '',
                  message: err.message,
                  show: true,
                  variant: 'danger',
                },
              });
            });
        },
      );
    }
  };

  render() {
    const {
      alertData,
      loading,
      export_list,
      pagginInfo,
      add_list_modal,
      edit_list_modal,
      edit_list_data,
      clients,
      exporting_all,
      cloneListData,
    } = this.state;

    return (
      <React.Fragment>
        <div className="pageHeading d-flex align-items-center justify-content-between">
          <h2>Export List</h2>
          <div className="">
            <Button
              className="btn-small mr-3 solid"
              onClick={() => this.toggleModal('add_list_modal', true)}>
              <span>Add New</span>
            </Button>

            <Button
              className="btn-small mr-3 solid"
              onClick={() => this.exportAll()}>
              {!exporting_all && <span>Export Now</span>}
              {exporting_all && (
                <i className="fas fa-spinner fa-pulse loadingIcon" />
              )}
            </Button>
          </div>
        </div>
        <Card>
          <Card.Body>
            <div className="export-fields-list-wrapper">
              {alertData.show && (
                <AlertDismissible
                  show={alertData.show}
                  variant={alertData.variant}
                  heading={alertData.heading}
                  message={alertData.message}
                  closeAlert={this.closeAlert}
                />
              )}

              {this._render_pagging()}

              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th width="2%">{` `}</th>
                    <th width="400">List Name</th>
                    <th width="100">File Name</th>
                    <th width="100">Client</th>
                    <th width="100">Schedule Time</th>
                    <th width="150">Next Schedule Run</th>
                    <th width="70" className="text-center">
                      File URL
                    </th>
                    <th width="150">Export list</th>
                    <th width="10%" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan="9" className="text-center">
                        <i className="fas fa-spinner fa-pulse loadingIcon" />
                      </td>
                    </tr>
                  )}
                  {!loading && Object.entries(export_list).length == 0 && (
                    <tr>
                      <td colSpan="9" className="text-center">
                        Export list is empty.{' '}
                        <span
                          className="asLink"
                          onClick={() =>
                            this.toggleModal('add_list_modal', true)
                          }>
                          Add New
                        </span>
                      </td>
                    </tr>
                  )}
                  {Object.entries(export_list).length > 0 &&
                    export_list.map((list, index) =>
                      this._render_list_item(
                        list,
                        index,
                        pagginInfo.indexStart,
                      ),
                    )}
                </tbody>
              </table>

              {this._render_pagging()}

              {add_list_modal && (
                <AddExportList
                  cloneData={cloneListData}
                  loading={loading}
                  clients={clients}
                  showModal={add_list_modal}
                  toggleModal={(modal, state) => this.toggleModal(modal, state)}
                  saveList={listData => this.saveList(listData)}
                />
              )}
              {edit_list_modal && (
                <EditExportList
                  clients={clients}
                  loading={loading}
                  listData={edit_list_data}
                  showModal={edit_list_modal}
                  toggleModal={(modal, state) => this.toggleModal(modal, state)}
                  updateList={listData => this.updateList(listData)}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  export_list: state.exportLists,
});

export default connect(mapStateProps, {
  addNewExportList,
  updateExportList,
  deleteExportList,
  getExportLists,
})(ExportList);
