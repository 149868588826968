export const appendFormData = data => {
  const formData = new FormData();

  if (Object.entries(data).length !== 0) {
    for (const [key, value] of Object.entries(data)) {
      // console.log("Form Data :", key, value);
      formData.append(key, value);
    }
  }

  return formData;
};

export const gen_uri = txt_src => {
  var output = txt_src
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .replace(/\s+/g, '-')
    .toLowerCase();
  /* remove first dash */
  if (output.charAt(0) == '-') output = output.substring(1);
  /* remove last dash */
  var last = output.length - 1;
  if (output.charAt(last) == '-') output = output.substring(0, last);

  return output;
};

export const downloadFile = async (
  url,
  filename = '',
  success_message = '',
) => {
  if (filename == '') {
    filename = url.split('/');
    filename = filename[filename.length - 1];
  }

  const results = await fetch(url)
    .then(async res => {
      const imgblob = await res.blob();

      if (imgblob) {
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(imgblob);
        a.href = url;
        a.download = filename;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        return {
          type: 'success',
          message: success_message
            ? success_message
            : 'File downloaded successfully!',
        };
      } else {
        return {
          type: 'error',
          message: 'Something went wrong please try again!',
        };
      }
    })
    .catch(err => {
      // console.log("Err :", err);
      return {
        type: 'error',
        message: err.message,
      };
    });
  return results;
};

export const copy_url = copyUrl => {
  const textField = document.createElement('textarea');
  textField.innerText = copyUrl;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};
