import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LightBox from "../../Elements/LightBox";

import { gen_uri } from "../../../utils/commonFunctions";
import { home_lable } from "../../../utils/language_untils";

const ListCetificates = (props) => {
    const { data, currentLang } = props;
    const [showLightbox, setShowLightbox] = useState(false);
    const [lightBoxImage, setPopupImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState([]);

    const {
        logos_design_awards,
        logos_testberichte,
        zertifikat_testberichte,
        award_1,
    } = data.product_fields;

    const testberichte_name = gen_uri(`${data.product_name}-testberichte`);
    const design_award_name = gen_uri(`${data.product_name}-design-award`);

    // console.log("logos_design_awards", logos_design_awards);
    // console.log("logos_testberichte", logos_testberichte);
    // console.log("zertifikat_testberichte", zertifikat_testberichte);
    // console.log("award_1", award_1);

    return (
        <>
            <div className="certification-wrapper">
                <h3>{home_lable.show_certificates[currentLang]}</h3>
                {(Object.entries(zertifikat_testberichte).length > 0 ||
                    Object.entries(award_1).length > 0) && (
                    <div className="certification-pdfs pdf-lists">
                        <ul>
                            {Object.entries(zertifikat_testberichte).length >
                                0 && (
                                <li
                                    className="asLink"
                                    onClick={() =>
                                        props.downloadFile(
                                            zertifikat_testberichte[0],
                                            testberichte_name
                                        )
                                    }
                                >
                                    <i className="far fa-file-pdf" />
                                    {
                                        home_lable.download_test_report[
                                            currentLang
                                        ]
                                    }
                                </li>
                            )}
                            {Object.entries(award_1).length > 0 && (
                                <li
                                    className="asLink"
                                    onClick={() =>
                                        props.downloadFile(
                                            award_1[0],
                                            design_award_name
                                        )
                                    }
                                >
                                    <i className="far fa-file-pdf" />
                                    {
                                        home_lable.download_award_design[
                                            currentLang
                                        ]
                                    }
                                </li>
                            )}
                        </ul>
                    </div>
                )}

                {(Object.entries(logos_design_awards).length > 0 ||
                    Object.entries(logos_testberichte).length > 0) && (
                    <div className="certification-images">
                        <Row>
                            {Object.entries(logos_design_awards).length > 0 && (
                                <Col>
                                    <div className="item">
                                        <h4>
                                            {
                                                home_lable.logos_award_design[
                                                    currentLang
                                                ]
                                            }
                                        </h4>
                                        <div className="logo-wrapper">
                                            {logos_design_awards.map(
                                                (image, index) => {
                                                    return (
                                                        <span
                                                            className="asLink"
                                                            onClick={() => {
                                                                setPopupImages(
                                                                    logos_design_awards
                                                                );
                                                                setCurrentIndex(
                                                                    index
                                                                );
                                                                setShowLightbox(
                                                                    true
                                                                );
                                                            }}
                                                            key={`design_awards_img_${index}`}
                                                        >
                                                            <LazyLoadImage
                                                                alt={""}
                                                                effect="blur"
                                                                src={image}
                                                                placeholder={""}
                                                            />
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            )}
                            {Object.entries(logos_testberichte).length > 0 && (
                                <Col>
                                    <div className="item">
                                        <h4>
                                            {
                                                home_lable.logos_testberichte[
                                                    currentLang
                                                ]
                                            }
                                        </h4>
                                        <div className="logo-wrapper">
                                            {logos_testberichte.map(
                                                (image, index) => {
                                                    return (
                                                        <span
                                                            className="asLink"
                                                            key={`logos_testberichte_img_${index}`}
                                                            onClick={() => {
                                                                setPopupImages(
                                                                    logos_testberichte
                                                                );
                                                                setShowLightbox(
                                                                    true
                                                                );
                                                                setCurrentIndex(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <LazyLoadImage
                                                                alt={""}
                                                                effect="blur"
                                                                src={image}
                                                                placeholder={""}
                                                            />
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </div>
                )}
            </div>

            {showLightbox && (
                <LightBox
                    images={lightBoxImage}
                    currentIndex={currentIndex}
                    videos={[]}
                    closeLightBox={() => setShowLightbox(false)}
                    downloadFile={(url, name) => props.downloadFile(url, name)}
                />
            )}
        </>
    );
};

export default ListCetificates;
