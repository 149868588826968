import React from "react";
import PropTypes, { string } from "prop-types";
import { Button } from "react-bootstrap";

function UsersList(props) {
    let userType = props.user.user_type;
    userType = userType.toLowerCase();
    return (
        <tr>
            <td>{props.index + 1}</td>
            <td>{`${props.user.name}`}</td>
            <td>{props.user.email}</td>
            <td className="text-center">{userType === "administrator" ? "Admin" : "Normal"}</td>
            <td className="text-center">
                {props.user.status === "1" ? (
                    <i className="fas fa-user-check success-icon" />
                ) : (
                    <i className="fas fa-user-times danger-icon" />
                )}
            </td>
            <td className="text-center actions">
                <Button onClick={() => props.showEditModal(props.index)} className="btn mr-2 solid btn-rounded">
                    <i className="fas fa-edit" />
                </Button>
                {props.currentUser !== props.user.id && (
                    <Button
                        onClick={() => props.removeUser(props.user.id)}
                        className="btn solid btn-danger btn-rounded"
                    >
                        <i className="fas fa-trash" />
                    </Button>
                )}
            </td>
        </tr>
    );
}

UsersList.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        fname: PropTypes.string.isRequired,
        lname: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        isConfirmed: PropTypes.bool.isRequired,
        usertype: PropTypes.string,
    }).isRequired,
    removeUser: PropTypes.func.isRequired,
    showEditModal: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

export default UsersList;
