import {
    GET_EXPORT_LIST,
    ADD_NEW_LIST,
    UPDATE_NEW_LIST,
    DELETE_NEW_LIST,
    SAVE_EXPORT_COLUMNS,
    EXPORT_PREVIEW_LIST,
} from "../utils/types";
import api from "../utils/api";

/* ###### Add Export List Actions ###### */
export const get_export_list_dispatch = (lists) => ({
    type: GET_EXPORT_LIST,
    lists,
});

export const getExportLists =
    (page, search = "") =>
    (dispatch) =>
        api.admin.getExportLists(page, search).then((res) => {
            const lists = res.lists;
            if (lists) {
                dispatch(get_export_list_dispatch(lists));
            }
            return res;
        });

/* ================================= */

export const save_export_list_dispatch = (list) => ({
    type: ADD_NEW_LIST,
    list,
});

export const addNewExportList = (data) => (dispatch) =>
    api.admin.addNewExportList(data).then((res) => {
        const list = res.list;
        if (list) {
            dispatch(save_export_list_dispatch(list));
        }
        return res;
    });

/* ================================= */

export const update_export_list_dispatch = (list) => ({
    type: UPDATE_NEW_LIST,
    list,
});

export const updateExportList = (data) => (dispatch) =>
    api.admin.updateExportList(data).then((res) => {
        const list = res.list;
        if (list) {
            dispatch(update_export_list_dispatch(list));
        }
        return res;
    });

/* ================================= */

export const remove_export_list_dispatch = (list) => ({
    type: DELETE_NEW_LIST,
    list,
});

export const deleteExportList = (id) => (dispatch) =>
    api.admin.deleteExportList(id).then((res) => {
        const list = res.list;
        if (list) {
            dispatch(remove_export_list_dispatch(list));
        }
        return res;
    });

/* ================================= */

export const get_preview_products_dispatch = (products) => ({
    type: EXPORT_PREVIEW_LIST,
    products,
});

export const getPreviewProducts = (id) => (dispatch) => api.admin.getPreviewProducts(id);

/* ###### Export Details Page Action ###### */
export const save_column_dispatch = (list) => ({
    type: SAVE_EXPORT_COLUMNS,
    list,
});

export const save_export_columns = (data) => (dispatch) =>
    api.admin.saveExportColumns(data).then((res) => {
        const list = res.list;
        if (list) {
            dispatch(save_column_dispatch(list));
        }
        return res;
    });

export const get_export_columns = (data) => (dispatch) =>
    api.admin.getExportColumns(data).then((res) => {
        const list = res.list;
        if (list) {
            dispatch(save_column_dispatch(list));
        }
        return res;
    });
