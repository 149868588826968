/* eslint-disable no-case-declarations */
import {
    ADMIN_GET_PRODUCTS,
    ADMIN_UPDATE_PRODUCT,
    ADMIN_REMOVE_PRODUCT,
} from "../utils/types";

export default (state = {}, action = {}) => {
    let updatedProject;
    switch (action.type) {
        // Projects API
        case ADMIN_GET_PRODUCTS:
            return action.products;
        case ADMIN_UPDATE_PRODUCT:
            const updatedProjects = state.map((product) => {
                if (product.id === action.product.id)
                    return { ...product, ...action.product };
                return product;
            });

            return updatedProjects;
        case ADMIN_REMOVE_PRODUCT:
            updatedProject = state.filter((product) => {
                return product.id !== action.product.id;
            });
            return updatedProject;
        default:
            return state;
    }
};
