/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import { LazyLoadImage } from "react-lazy-load-image-component";

import videoImg from "../../Assets/images/default-video.png";
import defaultImg from "../../Assets/images/default-img.jpg";

class LightBox extends Component {
    state = {
        images: this.props.images,
        videos: this.props.videos,
        currentIndex: this.props.currentIndex ? this.props.currentIndex : 0,
        nav1: null,
        nav2: null,
    };

    UNSAFE_componentDidMount() {
        const { currentIndex } = this.state;

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        const { images, videos, currentIndex } = this.state;
        // console.log("Videos :", images.length, videos, currentIndex);
        let thumbsSetting = {
            centerMode: true,
            infinite: true,
            responsive: [
                {
                    breakpoint: 1366,
                    settings: {
                        slidesToShow: 5,
                    },
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                    },
                },
            ],
        };

        if (images.length < 7) {
            thumbsSetting = {
                ...thumbsSetting,
                centerMode: true,
                infinite: false,
            };
        }

        let tempIndex = 0;
        let tempThumbIndex = 0;
        return (
            <div className="light-box-wrapper animate animated fadeInUp">
                <span className="close" onClick={() => this.props.closeLightBox()}>
                    <i className="fas fa-times" />
                </span>
                <div className="light-box">
                    <div className="lighbox-images">
                        <div className="img-container">
                            <Slider
                                asNavFor={this.state.nav2}
                                initialSlide={currentIndex}
                                ref={(slider) => (this.slider1 = slider)}
                            >
                                {images &&
                                    images.map((img, index) => {
                                        const temp = tempIndex;
                                        tempIndex = tempIndex + 1;
                                        const imageName = img.split("/");
                                        const name = imageName[imageName.length - 1];
                                        return (
                                            <div className="slide-img-wrap" key={temp}>
                                                <span
                                                    className="downloadImg"
                                                    onClick={() => this.props.downloadFile(img, name)}
                                                >
                                                    <i className="fa fa-file-download"></i>
                                                </span>
                                                <LazyLoadImage
                                                    alt={""}
                                                    effect="blur"
                                                    src={img}
                                                    placeholderSrc={defaultImg}
                                                />
                                            </div>
                                        );
                                    })}
                                {videos &&
                                    videos.map((vid, index) => {
                                        const temp = tempIndex;
                                        tempIndex = tempIndex + 1;
                                        const imageName = vid.split("/");
                                        const name = imageName[imageName.length - 1];
                                        return (
                                            <div key={temp} className="video-slide">
                                                <span
                                                    className="downloadImg"
                                                    onClick={() => this.props.downloadFile(vid, name)}
                                                >
                                                    <i className="fa fa-file-download"></i>
                                                </span>
                                                <video width="768" height="540" controls>
                                                    <source src={vid} type="video/mp4" />
                                                    <source src={vid} type="video/ogg" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        );
                                    })}
                            </Slider>
                        </div>
                    </div>
                    <div className="lighbox-thumbnails">
                        <Slider
                            asNavFor={this.state.nav1}
                            ref={(slider) => (this.slider2 = slider)}
                            initialSlide={currentIndex}
                            arrows={false}
                            slidesToShow={7}
                            swipeToSlide={true}
                            focusOnSelect={true}
                            {...thumbsSetting}
                        >
                            {images &&
                                images.map((img, index) => {
                                    const temp = tempThumbIndex;
                                    tempThumbIndex = tempThumbIndex + 1;
                                    return (
                                        <div key={temp}>
                                            <LazyLoadImage
                                                alt={""}
                                                effect="blur"
                                                src={img}
                                                placeholderSrc={defaultImg}
                                            />
                                        </div>
                                    );
                                })}
                            {videos &&
                                videos.map((video, index) => {
                                    const temp = tempThumbIndex;
                                    tempThumbIndex = tempThumbIndex + 1;

                                    return (
                                        <div key={temp}>
                                            <LazyLoadImage
                                                alt={""}
                                                effect="blur"
                                                src={videoImg}
                                                placeholderSrc={defaultImg}
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

LightBox.propTypes = {
    images: PropTypes.arrayOf.isRequired,
    closeLightBox: PropTypes.func.isRequired,
};

export default LightBox;
