import React, {Component} from 'react';

import {connect} from 'react-redux';
import {Card, Image, Form, Button} from 'react-bootstrap';

import AlertDismissible from '../../../Elements/AlertDismissible';
import {
  adminGetFamilies,
  adminEditFamilie,
  adminRemoveFamilie,
} from '../../../../Actions/AdminActions';
import EditFamilyTranslations from '../Modals/EditFamilyTranslations';
import {appendFormData} from '../../../../utils/commonFunctions';

class ProductFamilie extends Component {
  state = {
    families: [],
    editFamily: {},
    editFamilyModal: false,
    loading: false,
    errors: [],
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
    pagginInfo: [],
  };

  UNSAFE_componentWillMount = () => {
    this.get_products();
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {families} = nextProps;

    if (Object.entries(families).length !== 0) {
      families.map((familie, index) => {
        if (typeof familie.status !== 'boolean') {
          families[index]['status'] = familie.status === '1' ? true : false;
        }
      });
    }

    this.setState({
      families,
    });
  };

  get_products = (page = 1) => {
    this.props.adminGetFamilies(page).then(res => {
      if (res.type === 'success') {
        this.setState({pagginInfo: res.pagging_info});
      } else if (res.type === 'error') {
      }
    });
  };

  updateFamilieStatus = (FamilieId, e) => {
    const status = e.target.checked ? 1 : 0;
    const cateDatas = new FormData();
    cateDatas.append('familie_id', FamilieId);
    cateDatas.append('status', status);

    this.props
      .adminEditFamilie(cateDatas)
      .then(res => {
        this.setState({
          alertData: {
            heading: '',
            message: res.message,
            show: true,
            variant: res.type === 'error' ? 'danger' : 'success',
          },
        });
      })
      .catch(err => {
        this.setState({
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  removeItem = id => {
    this.props
      .adminRemoveFamilie(id)
      .then(res => {
        this.setState({
          alertData: {
            heading: '',
            message: res.message,
            show: true,
            variant: res.type === 'error' ? 'danger' : 'success',
          },
        });
      })
      .catch(err => {
        this.setState({
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  toggleModal = (modal, value) => {
    this.setState({
      ...this.state,
      [modal]: value,
    });

    return '';
  };

  editFamily = familie => {
    this.setState({
      editFamily: familie,
      editFamilyModal: true,
    });
  };

  updateFamily = data => {
    this.setState({loading: true});

    this.props
      .adminEditFamilie(data)
      .then(res => {
        this.setState({
          loading: false,
          editFamily: {},
          editFamilyModal: false,
          alertData: {
            heading: '',
            message: res.message,
            show: true,
            variant: res.type === 'error' ? 'danger' : 'success',
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          editFamily: {},
          editFamilyModal: false,
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  renderFamilieItem = (familie, index, startIndex) => {
    startIndex = startIndex + index;
    return (
      <tr key={index}>
        <td>{startIndex}</td>
        <td>{familie.familie_name}</td>
        <td className="text-center">
          <Form.Check
            type="switch"
            id={`familie_${familie.id}`}
            name={`familie_${familie.id}`}
            value={familie.id}
            checked={familie.status ? 'checked' : ''}
            onChange={e => {
              this.updateFamilieStatus(familie.id, e);
            }}
            label=""
          />
        </td>
        <td className="text-center">
          <Button
            onClick={() => this.editFamily(familie)}
            className="btn solid mr-2 btn-rounded">
            <i className="fas fa-edit" />
          </Button>
          <Button
            onClick={() => this.removeItem(familie.id)}
            className="btn solid btn-danger btn-rounded">
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    );
  };

  show_pagging = () => {
    const {pagginInfo} = this.state;
    const nextClass = pagginInfo.next === 'disable' ? 'disabled' : '';
    const prvClass = pagginInfo.previous === 'disable' ? 'disabled' : '';
    return (
      <div className="pagging-info">
        {pagginInfo.total_item} items
        <span
          className={`nav-btn prv ${prvClass}`}
          onClick={() => {
            if (pagginInfo.previous !== 'disable')
              this.get_products(pagginInfo.previous);
          }}>
          ‹
        </span>
        <span className="page-text">
          Current Page {pagginInfo.current} of {pagginInfo.total_pages}
        </span>
        <span
          className={`nav-btn next ${nextClass}`}
          onClick={() => {
            if (pagginInfo.next !== 'disable')
              this.get_products(pagginInfo.next);
          }}>
          ›
        </span>
      </div>
    );
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        heading: '',
        message: '',
        show: false,
        variant: '',
      },
    });
  };

  render() {
    const {
      alertData,
      families,
      editFamily,
      editFamilyModal,
      pagginInfo,
      loading,
    } = this.state;

    return (
      <React.Fragment>
        <div className="pageHeading d-flex">
          <h2>Families</h2>
          <div className="extras align-self-center ml-auto"></div>
        </div>
        {alertData.show && (
          <AlertDismissible
            show={alertData.show}
            variant={alertData.variant}
            heading={alertData.heading}
            message={alertData.message}
            closeAlert={this.closeAlert}
          />
        )}
        <Card>
          <Card.Body>
            {this.show_pagging()}

            <div className="data-listing">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th width="1%" />
                    <th width="80%">Familie Name</th>
                    <th width="2%" className="text-center">
                      Status
                    </th>
                    <th width="10%" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(families).length !== 0 &&
                    families.map((familie, index) =>
                      this.renderFamilieItem(
                        familie,
                        index,
                        pagginInfo.indexStart,
                      ),
                    )}
                  {Object.entries(families).length === 0 && (
                    <tr>
                      <td colSpan="4">
                        <p className="text-center">No Products Available.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {this.show_pagging()}
          </Card.Body>
        </Card>

        {editFamilyModal && (
          <EditFamilyTranslations
            loading={loading}
            showModal={editFamilyModal}
            family={editFamily}
            updateFamily={data => {
              this.updateFamily(data);
            }}
            onClose={(modal, status) => this.toggleModal(modal, status)}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  families: state.adminFamiliesList,
});

export default connect(mapStateProps, {
  adminGetFamilies,
  adminEditFamilie,
  adminRemoveFamilie,
})(ProductFamilie);
