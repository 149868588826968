/* eslint-disable no-case-declarations */
import {
    ADMIN_GET_CATEGORIES,
    ADMIN_UPDATE_CATEGORY,
    ADMIN_REMOVE_CATEGORY,
} from "../utils/types";

export default (state = {}, action = {}) => {
    let updatedProject;
    switch (action.type) {
        // Projects API
        case ADMIN_GET_CATEGORIES:
            return action.categories;
        case ADMIN_UPDATE_CATEGORY:
            const updatedProjects = state.map((category) => {
                if (category.id === action.category.id)
                    return { ...category, ...action.category };
                return category;
            });
            return updatedProjects;
        case ADMIN_REMOVE_CATEGORY:
            updatedProject = state.filter((category) => {
                return category.id !== action.category.id;
            });
            return updatedProject;
        default:
            return state;
    }
};
