import React from "react";
import PropTypes from "prop-types";

const FloatingAlert = (props) => {
    const postion = props.position ? props.position : "top-right";

    const currentLang = props.currentLang ? props.currentLang : "en";

    const message = props.message[currentLang]
        ? props.message[currentLang]
        : "";

    // const message = props.message[cu]

    return (
        <React.Fragment>
            {props.show && (
                <div
                    className={`floating-alert ${postion} alert alert-${props.variant}`}
                >
                    {props.closeAlert && (
                        <button
                            type="button"
                            className="close"
                            onClick={() => props.closeAlert()}
                        >
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close alert</span>
                        </button>
                    )}
                    {props.heading && (
                        <div className="alert-heading h4">{props.heading}</div>
                    )}
                    <p>{message}</p>
                </div>
            )}
        </React.Fragment>
    );
};

FloatingAlert.propTypes = {
    show: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
    closeAlert: PropTypes.func.isRequired,
    position: PropTypes.string,
};

export default FloatingAlert;
