import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import { getPreviewProducts } from "../../../../Actions/ExportActions";
import AlertDismissible from "../../../Elements/AlertDismissible";

class PreviewCsvModal extends Component {
    state = {
        loading: true,
        products: {},
        list_id: this.props.list_id,
        columns: this.props.columns,
        alertData: {
            heading: "",
            message: "",
            show: false,
            variant: "",
        },
    };

    UNSAFE_componentWillMount = () => {
        const { list_id } = this.state;
        const formData = new FormData();
        formData.append("list_id", list_id);
        this.props
            .getPreviewProducts(formData)
            .then((res) => {
                this.setState({
                    loading: false,
                    products: res.products ? res.products : {},
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    alertData: {
                        heading: "",
                        message: err.message,
                        show: true,
                        variant: "danger",
                    },
                });
            });
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        const { columns } = props;
        this.setState({
            columns,
        });
    };

    _render_product = (product) => {
        const { columns } = this.state;

        return (
            <tr>
                {Object.entries(columns).length > 0 &&
                    columns.map((column, index) => {
                        let productFieldVal = "";
                        if (column.field_value === "Custom") {
                            productFieldVal = column.custom_value;
                        } else if (product[column.field_value]) {
                            productFieldVal = product[column.field_value];
                        } else if (product.product_fields[column.field_value]) {
                            if (Array.isArray(product.product_fields[column.field_value])) {
                                product.product_fields[column.field_value].map((field) => {
                                    if(Object.entries(field).length > 0) {
                                        Object.keys(field).forEach(fieldKey => {
                                            productFieldVal += `${field[fieldKey]} , `
                                        });
                                    } else {
                                        productFieldVal += `${field} , `;
                                    }
                                });
                                productFieldVal = productFieldVal.substring(0, productFieldVal.length - 2);
                            } else {
                                productFieldVal = product.product_fields[column.field_value];
                            }
                        } else {
                            productFieldVal = " - ";
                        }

                        return <td key={`preview_product_key_${index}`}>{productFieldVal}</td>;
                    })}
            </tr>
        );
    };

    closeAlert = () => {
        this.setState({
            alertData: {
                show: false,
            },
        });
    };
    render() {
        const { loading, products, columns, alertData } = this.state;
        return (
            <React.Fragment>
                <Modal
                    className="max"
                    show={this.props.showModal}
                    onHide={() => this.props.toggleModal("preview_csv_modal", false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>CSV Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="export-preview">
                            {alertData.show && (
                                <AlertDismissible
                                    show={alertData.show}
                                    variant={alertData.variant}
                                    heading={alertData.heading}
                                    message={alertData.message}
                                    closeAlert={this.closeAlert}
                                />
                            )}
                            <table className="table">
                                <thead className="thead-light">
                                    <tr>
                                        {Object.entries(columns).length > 0 &&
                                            columns.map((column) => <th>{column.title}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && Object.entries(products).length === 0 && (
                                        <tr>
                                            <td colSpan={Object.entries(columns).length} class="text-center">
                                                {" "}
                                                No Products Available
                                            </td>
                                        </tr>
                                    )}
                                    {loading && Object.entries(products).length === 0 && (
                                        <tr>
                                            <td colSpan={Object.entries(columns).length} class="text-center">
                                                <i className="fas fa-spinner fa-pulse loadingIcon" />
                                            </td>
                                        </tr>
                                    )}                                    
                                    {Object.entries(products).length > 0 &&
                                        products.map((product) => this._render_product(product))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
const mapStateProps = (state) => ({});

export default connect(mapStateProps, { getPreviewProducts })(PreviewCsvModal);
