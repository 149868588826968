/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import validate from "validator";
import { Card, Row, Col, Form, FormCheck, Button, FormControl } from "react-bootstrap";

import AlertDismissible from "../../../Elements/AlertDismissible";
import { saveSettings, getSettings, importNow } from "../../../../Actions/AdminActions";

class SettingsPage extends Component {
    state = {
        loading: false,
        importLoading: false,
        data: {
            connector_id: "",
            secret_key: "",
            cron_schedule: "",
        },
        errors: {},
        validated: false,
        alertData: {
            heading: "",
            message: "",
            show: false,
            variant: "",
        },
    };

    UNSAFE_componentWillMount = () => {
        this.props.getSettings();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { settings } = nextProps;

        console.log("new Settings:", settings);

        this.setState({
            data: settings,
        });
    };

    onChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value,
            },
        });
    };

    importNow = () => {
        const { importLoading } = this.state;

        if (!importLoading) {
            this.setState({
                importLoading: true,
            });

            this.props.importNow().then((res) => {
                if (res.type === "success") {
                    this.setState({
                        importLoading: false,
                        alertData: {
                            heading: "",
                            message: res.message,
                            show: true,
                            variant: "success",
                        },
                    });
                } else {
                    this.setState({
                        importLoading: false,
                        alertData: {
                            heading: "",
                            message: res.message,
                            show: true,
                            variant: "danger",
                        },
                    });
                }
            });
        }
    };

    onSubmit = () => {
        const { data, loading } = this.state;
        const errors = this.validateData(data);
        if (!loading) {
            if (Object.entries(errors).length === 0) {
                this.setState({ loading: true });

                const formData = new FormData();
                formData.append("connector_id", data.connector_id);
                formData.append("secret_key", data.secret_key);
                formData.append("cron_schedule", data.cron_schedule);

                this.props
                    .saveSettings(formData)
                    .then((res) => {
                        if (res.type === "success") {
                            this.setState({
                                loading: false,
                                alertData: {
                                    heading: "",
                                    message: res.message,
                                    show: true,
                                    variant: "success",
                                },
                            });
                        } else {
                            this.setState({
                                loading: false,
                                alertData: {
                                    heading: "",
                                    message: res.message,
                                    show: true,
                                    variant: "danger",
                                },
                            });
                        }
                    })
                    .catch((err) => {});
            } else {
                this.setState({ errors, loading: false, validated: true });
            }
        }
    };

    validateData = (data) => {
        const errors = {};
        if (data.connector_id === "") errors.connector_id = "Please Connector ID.";
        if (data.secret_key === "") errors.secret_key = "Please Secret Key.";
        if (data.cron_schedule === "") errors.cron_schedule = "Please select schedule.";
        // if (data.cron_schedule === "") errors.designPrice = "Please enter price.";

        return errors;
    };

    checkboxHandle = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.checked.toString(),
            },
        });
    };

    changeTag = (e, tab) => {
        const tabContent = document.querySelector(".tab-content.active");
        const mdTab = document.querySelector(".md-tabs li span.active");

        tabContent.classList.remove("active");
        mdTab.classList.remove("active");
        document.getElementById(`${tab}-content`).classList.add("active");
        e.target.classList.add("active");
    };

    closeAlert = () => {
        this.setState({
            alertData: {
                show: false,
            },
        });
    };

    render() {
        const { loading, data, errors, validated, alertData, importLoading } = this.state;
        const hourlySelected = data.cron_schedule === "hourly" ? "selected" : "";
        const twoHourSelected = data.cron_schedule === "2-hourly" ? "selected" : "";
        const dailySelected = data.cron_schedule === "daily" ? "selected" : "";
        const weeklySelected = data.cron_schedule === "weekly" ? "selected" : "";
        return (
            <React.Fragment>
                <div className="pageHeading d-flex">
                    <h2>Settings</h2>
                </div>
                {alertData.show && (
                    <AlertDismissible
                        show={alertData.show}
                        variant={alertData.variant}
                        heading={alertData.heading}
                        message={alertData.message}
                        closeAlert={this.closeAlert}
                    />
                )}
                <Card>
                    <Card.Body>
                        <Form validated={validated}>
                            <div className="md-tabs">
                                <ul>
                                    <li>
                                        <span
                                            className="active"
                                            onClick={(e) => {
                                                this.changeTag(e, "general");
                                            }}
                                        >
                                            Sales Layer Info
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            className=""
                                            onClick={(e) => {
                                                this.changeTag(e, "cron-job-wrapper");
                                            }}
                                        >
                                            Schedule Data Sync
                                        </span>
                                    </li>
                                </ul>

                                <div id="general-content" className="tab-content active">
                                    <Row>
                                        <Col>
                                            <Form.Group className="with-icon">
                                                <i className="fas fa-lock" />
                                                <FormControl
                                                    type="text"
                                                    name="connector_id"
                                                    placeholder="Connector ID"
                                                    value={data.connector_id}
                                                    required
                                                    onChange={(e) => this.onChange(e)}
                                                />
                                                {errors.connector_id && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.connector_id}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Form.Group className="with-icon">
                                                <i className="fas fa-key" />
                                                <FormControl
                                                    type="text"
                                                    name="secret_key"
                                                    placeholder="Secret Key"
                                                    value={data.secret_key}
                                                    required
                                                    onChange={(e) => this.onChange(e)}
                                                />
                                                {errors.secret_key && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.secret_key}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <div id="cron-job-wrapper-content" className="tab-content">
                                    <Row>
                                        <Col md={10}>
                                            <Form.Group controlId="exampleForm.ControlSelect1 with-icon">
                                                <Form.Control
                                                    name="cron_schedule"
                                                    required
                                                    onChange={(e) => this.onChange(e)}
                                                    as="select"
                                                >
                                                    <option value="">Select Schedule Time</option>
                                                    <option selected={hourlySelected} value="hourly">
                                                        Every Hour
                                                    </option>
                                                    <option selected={twoHourSelected} value="2-hourly">
                                                        Every Two Hour
                                                    </option>
                                                    <option selected={dailySelected} value="daily">
                                                        Daily
                                                    </option>
                                                    <option selected={weeklySelected} value="weekly">
                                                        Weekly
                                                    </option>
                                                </Form.Control>
                                                {errors.cron_schedule && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.cron_schedule}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <label>Next Run : {data.cron_last_run}</label>
                                        </Col>
                                        <Col>
                                            <Button className="btn btn-medium" onClick={() => this.importNow()}>
                                                {!importLoading && <span>Import Now</span>}
                                                {importLoading && (
                                                    <span>
                                                        <i className="fas fa-spinner fa-pulse loadingIcon" />
                                                    </span>
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <br /> <br />
                            <Button className="btn btn-medium" onClick={() => this.onSubmit()}>
                                <span>Save</span>
                            </Button>
                            {loading && <i className="fas fa-spinner fa-pulse loadingIcon" />}
                        </Form>
                    </Card.Body>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateProps = (state) => ({
    settings: state.Settings,
});

SettingsPage.propTypes = {
    settings: PropTypes.arrayOf.isRequired,
    saveSettings: PropTypes.func.isRequired,
};

export default connect(mapStateProps, { saveSettings, getSettings, importNow })(SettingsPage);
