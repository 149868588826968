import React, {Component} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

import {appendFormData} from '../../../../utils/commonFunctions';

class EditFamilyTranslations extends Component {
  state = {
    loading: this.props.loading,
    data: this.props.family,
    show_password: false,
    errors: {},
    validated: false,
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {loading} = props;

    this.setState({
      loading,
    });
  };

  onChange = e => {
    let {data} = this.state;

    data.translations = {
      ...this.state.data.translations,
      [e.target.name]: e.target.value,
    };

    this.setState({
      data,
    });
  };

  validateData = () => {
    const errors = {};
    const {data} = this.state;

    if (!data.translations.en || data.translations.en === '') {
      errors.en = 'Please enter English Translation.';
    }
    if (!data.translations.de || data.translations.de === '') {
      errors.de = 'Please enter German Translation.';
    }
    if (!data.translations.fr || data.translations.fr === '') {
      errors.fr = 'Please enter French Translation.';
    }
    if (!data.translations.it || data.translations.it === '') {
      errors.it = 'Please enter Italian Translation.';
    }

    return errors;
  };

  onSubmit = () => {
    const {data} = this.state;
    const errors = this.validateData();

    // console.log("errors", errors);

    if (!Object.entries(errors).length) {
      const formData = appendFormData(data);
      formData.append('familie_id', data.id);
      formData.append('update_family', 'true');
      formData.append('translations', JSON.stringify(data.translations));
      this.props.updateFamily(formData);
    } else {
      this.setState({
        validated: true,
        errors,
      });
    }
  };

  render() {
    const {validated, loading, data, errors} = this.state;

    const {en, de, fr, it} = data.translations;

    return (
      <Modal
        className="medium"
        show={this.props.showModal}
        onHide={() => this.props.onClose('editFamilyModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update translations for {data.familie_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} onSubmit={() => this.onSubmit()}>
            <Form.Group>
              <Form.Control
                type="text"
                name="en"
                value={en}
                required
                placeholder="Translation"
                onChange={e => this.onChange(e)}
              />
              {errors.en && (
                <Form.Control.Feedback type="invalid">
                  {errors.en}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="de"
                value={de}
                required
                placeholder="Übersetzung"
                onChange={e => this.onChange(e)}
              />
              {errors.de && (
                <Form.Control.Feedback type="invalid">
                  {errors.de}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="fr"
                value={fr}
                required
                placeholder="Traduction"
                onChange={e => this.onChange(e)}
              />
              {errors.fr && (
                <Form.Control.Feedback type="invalid">
                  {errors.fr}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="it"
                value={it}
                required
                placeholder="Traduzione"
                onChange={e => this.onChange(e)}
              />
              {errors.it && (
                <Form.Control.Feedback type="invalid">
                  {errors.it}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn solid btn-medium"
            onClick={() => this.onSubmit()}>
            {!loading && <span>Save</span>}
            {loading && (
              <span>
                <i className="fas fa-spinner fa-pulse loadingIcon" />
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditFamilyTranslations;
