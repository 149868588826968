import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Card} from 'react-bootstrap';

import UsersList from '../PageElements/UsersList';
import AddUserModal from '../Modals/AddUserModal';
import EditUserModal from '../Modals/EditUserModal';

import AlertDismissible from '../../../Elements/AlertDismissible';
import {
  adminGetUsers,
  adminAddUser,
  adminEditUser,
  adminRemoveUser,
} from '../../../../Actions/AdminActions';

class UsersPage extends Component {
  state = {
    users: [],
    currentUser: '',
    page: 1,
    addUserModal: false,
    editUserModal: false,
    indexToEdit: '',
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
  };
  UNSAFE_componentWillMount() {
    const {page} = this.state;
    this.props.adminGetUsers(page);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {users, currentUser} = nextProps;
    this.setState({
      users,
      currentUser: currentUser.uid,
    });
  }

  adminAddUser = data => this.props.adminAddUser(data);
  adminEditUser = user => this.props.adminEditUser(user);
  adminRemoveUser = user =>
    this.props
      .adminRemoveUser(user)
      .then(res => {
        // console.log(res);
        if (res.type === 'success') {
          this.setState({
            alertData: {
              heading: '',
              message: res.message,
              show: true,
              variant: 'success',
            },
          });
        } else if (res.type === 'error') {
          this.setState({
            alertData: {
              heading: '',
              message: res.message,
              show: true,
              variant: 'danger',
            },
          });
        }
      })
      .catch(err => {});

  toggleModal = (modal, value) => {
    switch (modal) {
      case 'addUserModal':
        this.setState({addUserModal: value});
        break;
      case 'editUserModal':
        this.setState({editUserModal: value});
        break;
      default:
        break;
    }
    // console.log(this.state);
    return '';
  };

  showEditModal = index => {
    this.setState({
      editUserModal: true,
      indexToEdit: index,
    });
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        show: false,
      },
    });
  };

  render() {
    const {
      users,
      currentUser,
      addUserModal,
      editUserModal,
      indexToEdit,
      alertData,
    } = this.state;
    let usertoEdit = '';
    if (indexToEdit !== '') {
      usertoEdit = users[indexToEdit];
    }

    return (
      <React.Fragment>
        <div className="pageHeading d-flex">
          <h2>Users List</h2>
          <div className="extras align-self-center ml-auto">
            <span
              className="add"
              onClick={() => this.toggleModal('addUserModal', true)}>
              <i className="fas fa-plus-circle" /> Add User
            </span>
          </div>
        </div>
        {alertData.show && (
          <AlertDismissible
            show={alertData.show}
            variant={alertData.variant}
            heading={alertData.heading}
            message={alertData.message}
            closeAlert={this.closeAlert}
          />
        )}
        <Card>
          <Card.Body>
            <div className="data-listing">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th width="1%" />
                    <th width="13%">Name</th>
                    <th width="18%">Email</th>
                    <th width="4%" className="text-center">
                      Type
                    </th>
                    <th width="2%" className="text-center">
                      Status
                    </th>
                    <th width="4%" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(users).length !== 0 &&
                    users.map((user, index) => (
                      <UsersList
                        key={user.id}
                        currentUser={currentUser}
                        user={user}
                        index={index}
                        adminEditUser={this.adminEditUser}
                        showEditModal={this.showEditModal}
                        removeUser={this.adminRemoveUser}
                      />
                    ))}
                  {Object.entries(users).length === 0 && (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <p>No Users Found...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {addUserModal && (
          <AddUserModal
            adminAddUser={this.adminAddUser}
            showModal={this.state.addUserModal}
            toggleModal={this.toggleModal}
          />
        )}
        {editUserModal && (
          <EditUserModal
            user={usertoEdit}
            adminEditUser={this.adminEditUser}
            showModal={this.state.editUserModal}
            toggleModal={this.toggleModal}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  currentUser: state.user,
  users: state.adminUserList,
});

UsersPage.propTypes = {
  users: PropTypes.arrayOf.isRequired,
  adminGetUsers: PropTypes.func.isRequired,
  adminAddUser: PropTypes.func.isRequired,
  adminEditUser: PropTypes.func.isRequired,
  adminRemoveUser: PropTypes.func.isRequired,
};

export default connect(mapStateProps, {
  adminGetUsers,
  adminAddUser,
  adminEditUser,
  adminRemoveUser,
})(UsersPage);
