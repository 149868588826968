import { HOME_FILTERS, GET_FILTER_PRODUCTS, SET_LANGUAGE_PREF, HOME_FAMILIES } from "../utils/types";
import api from "../utils/api";

export const GetFilterOption = (filters) => ({
    type: HOME_FILTERS,
    filters,
});

export const GetFamilies = (families) => ({
    type: HOME_FAMILIES,
    families,
});

export const getHomeProduct = (products) => ({
    type: GET_FILTER_PRODUCTS,
    products,
});

export const setLanguage = (language) => ({
    type: SET_LANGUAGE_PREF,
    language,
});

export const getFilterOptions = () => (dispatch) =>
    api.home.getFilterOptions().then((res) => {
        const { filters } = res;
        if (filters) {
            dispatch(GetFilterOption(filters));
        }
        return res;
    });

export const get_famillies = (data) => (dispatch) =>
    api.home.get_famillies(data).then((res) => {
        const { families } = res;
        if (families) {
            dispatch(GetFamilies(families));
        }
        return res;
    });

export const getHomeProducts = (data) => (dispatch) =>
    api.home.getHomeProducts(data).then((res) => {
        const { products } = res;
        if (products) {
            dispatch(getHomeProduct(products));
        }
        return res;
    });

export const getSearchProducts = (data) => (dispatch) =>
    api.home.getSearchProducts(data).then((res) => {
        const { products } = res;
        if (products) {
            dispatch(getHomeProduct(products));
        }
        return res;
    });

export const getProductWithId = (id) => (dispatch) => api.home.getProductWithId(id);

export const setHomeLanguage = (lang) => (dispatch) => {
    localStorage.setItem("pim_language_pref", lang);
    dispatch(setLanguage(lang));
};
