import React, {Component} from 'react';
import {Button, Card, Form, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import AlertDismissible from '../../../Elements/AlertDismissible';

import AddColumnModal from '../Modals/AddColumnModal';
import EditColumnModal from '../Modals/EditColumnModal';
import PreviewCsvModal from '../Modals/PreviewCsvModal';
import {PreparingDownload} from '../../../Modals/PreparingDownload';

import {
  save_export_columns,
  get_export_columns,
} from '../../../../Actions/ExportActions';
import {appendFormData, downloadFile} from '../../../../utils/commonFunctions';
import {serverUrl} from '../../../../utils/env';
import {Link} from 'react-router-dom';

const DragHandle = sortableHandle(() => (
  <Button className="btn solid btn-info btn-rounded mr-2">
    <i className="fas fa-arrows-alt" />
  </Button>
));

const SortableContainer = sortableContainer(({children}) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = sortableElement(
  ({column, columnIndex, setState, toggleSelectMultiple, removeColumn}) => {
    const fieldVal =
      column.custom_value && column.custom_value !== ''
        ? column.custom_value
        : column.field_value;

    return (
      <tr className="columnItem">
        <td width="2%">
          <input
            type="checkbox"
            className="multiple_action_chk"
            name="multiple_action"
            onChange={e => toggleSelectMultiple(e, `${columnIndex}`)}
            value={columnIndex}
          />
        </td>
        <td width="15%">{column.title}</td>
        <td width="50%">{fieldVal}</td>
        <td width="10%" className="text-center">
          <DragHandle />

          <Button
            onClick={() => {
              setState({
                editColumnIndex: columnIndex,
                edit_column_modal: true,
              });
            }}
            className="btn solid mr-2 btn-rounded">
            <i className="fas fa-edit" />
          </Button>
          <Button
            onClick={() => removeColumn(columnIndex)}
            className="btn solid btn-danger btn-rounded">
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    );
  },
);

class ExportProductsSettings extends Component {
  state = {
    loading: false,
    list_exist: false,
    listData: {},
    columns: [],
    fields: {},
    add_column_modal: false,
    edit_column_modal: false,
    preview_csv_modal: false,
    download_preparing_modal: false,
    editColumnIndex: '',
    csv_download_url: `${serverUrl}/admin/export-csv`,
    multiple_remove_column: [],
    fields_dragged: false,
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
  };

  UNSAFE_componentWillMount = () => {
    const {id} = this.props.match.params;

    const formData = new FormData();
    formData.append('id', id);

    this.props
      .get_export_columns(formData)
      .then(res => {
        const state = this.state;
        if (res.type == 'error') {
          state.alertData = {
            heading: '',
            message: res.message,
            show: true,
            variant: 'danger',
          };
        }

        state.list_exist = res.list_exist == 'true' ? true : false;
        state.fields = res.fields;
        this.setState({state});
      })
      .catch(err => {
        this.setState({
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  UNSAFE_componentWillReceiveProps = props => {
    const {listData} = props;

    const columns = listData.columns ? listData.columns : [];

    this.setState({
      columns,
      listData,
    });
  };

  toggleModal = (modal, state) => {
    this.setState({
      ...this.state,
      [modal]: state,
    });
  };

  saveColumns = (newColumn = '') => {
    const {columns, listData} = this.state;

    if (newColumn) {
      columns.push(newColumn);
    }

    const data = appendFormData({
      columns: JSON.stringify(columns),
      id: listData.id,
    });
    this.setState({loading: true});
    this.props
      .save_export_columns(data)
      .then(res => {
        if (res.type === 'success') {
          this.setState({
            add_column_modal: false,
            edit_column_modal: false,
            loading: false,
            fields_dragged: false,
            alertData: {
              heading: '',
              message: res.message,
              show: true,
              variant: 'success',
            },
          });
        } else {
          this.setState({
            add_column_modal: false,
            loading: false,
            alertData: {
              heading: '',
              message: res.message,
              show: true,
              variant: 'danger',
            },
          });
        }
      })
      .catch(err => {
        this.setState({
          add_column_modal: false,
          edit_column_modal: false,
          loading: false,
          alertData: {
            heading: '',
            message: err.message,
            show: true,
            variant: 'danger',
          },
        });
      });
  };

  updateColumns = (updatedColumn, columnIndex) => {
    const {columns} = this.state;

    columns.splice(columnIndex, 1, updatedColumn);

    this.setState(
      {
        columns,
      },
      () => {
        this.saveColumns();
      },
    );
  };

  removeColumn = index => {
    const {columns} = this.state;
    columns.splice(index, 1);

    this.setState(
      {
        columns,
      },
      () => {
        setTimeout(() => {
          this.saveColumns();
        }, 200);
      },
    );
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        show: false,
      },
    });
  };

  downlod_product_csv = async () => {
    const {csv_download_url, listData} = this.state;
    const d = new Date();

    this.setState({download_preparing_modal: true});

    const downloadUrl = `${csv_download_url}/${listData.id}?download=true`;
    // console.log(downloadUrl);

    const res = await downloadFile(
      downloadUrl,
      `${listData.file_name}.csv`,
      'CSV Exported Successfully.',
    );

    this.setState({
      download_preparing_modal: false,
      alertData: {
        heading: '',
        message: res.message,
        show: true,
        variant: res.type == 'error' ? 'danger' : res.type,
      },
    });

    // console.log("results:", res);
  };

  removeSelected = () => {
    const {multiple_remove_column, columns} = this.state;

    multiple_remove_column.sort();
    multiple_remove_column.reverse();

    multiple_remove_column.map(index => {
      // console.log(index);
      columns.splice(index, 1);
    });

    this.setState(
      {
        columns,
        multiple_remove_column: [],
      },
      () => {
        this.saveColumns();
      },
    );
  };

  toggleAllSelect = e => {
    let {multiple_remove_column} = this.state;
    const isChecked = e.target.checked;
    const allChks = document.getElementsByClassName('multiple_action_chk');

    if (isChecked) {
      for (const input of allChks) {
        if (!multiple_remove_column.includes(input.value)) {
          multiple_remove_column.push(input.value);
        }
        input.checked = true;
      }
    } else {
      for (const input of allChks) {
        const currentChkVal = input.value;

        multiple_remove_column = multiple_remove_column.filter(val => {
          return val !== currentChkVal;
        });

        input.checked = false;
      }
    }

    this.setState({
      multiple_remove_column,
    });
  };

  toggleSelectMultiple = (e, index) => {
    let {multiple_remove_column} = this.state;
    const isChecked = e.target.checked;

    if (isChecked && !multiple_remove_column.includes(index)) {
      multiple_remove_column.push(index);
    } else if (!isChecked && multiple_remove_column.includes(index)) {
      multiple_remove_column = multiple_remove_column.filter(val => {
        return val !== index;
      });
    }

    this.setState({
      multiple_remove_column,
    });
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({columns}) => ({
      columns: arrayMove(columns, oldIndex, newIndex),
      fields_dragged: true,
    }));
  };

  render() {
    const {
      multiple_remove_column,
      fields_dragged,
      list_exist,
      alertData,
      listData,
      columns,
      loading,
      add_column_modal,
      edit_column_modal,
      preview_csv_modal,
      download_preparing_modal,
      fields,
      editColumnIndex,
    } = this.state;

    return (
      <React.Fragment>
        <div className="pageHeading d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="back-arrow mr-3">
              <Link to="/admin/export-lists">
                <i className="fas fa-chevron-left" />
              </Link>
            </span>
            <h2>{listData.list_name}</h2>
          </div>
          {list_exist && (
            <div className="">
              <Button
                className="btn-small mr-3 solid"
                onClick={() => this.toggleModal('add_column_modal', true)}>
                <span>Add Column</span>
              </Button>
              <Button
                className="btn-small mr-3 solid"
                onClick={() => this.toggleModal('preview_csv_modal', true)}>
                <span>Preview CSV</span>
              </Button>
              <Button
                className="btn-small solid"
                onClick={() => this.downlod_product_csv()}>
                <span>Export CSV</span>
              </Button>
            </div>
          )}
        </div>

        <Card>
          <Card.Body>
            <div className="export-fields-list-wrapper">
              {alertData.show && (
                <AlertDismissible
                  show={alertData.show}
                  variant={alertData.variant}
                  heading={alertData.heading}
                  message={alertData.message}
                  closeAlert={this.closeAlert}
                />
              )}
              {list_exist && (
                <div>
                  <div className="d-flex align-items-center">
                    {Object.entries(multiple_remove_column).length !== 0 && (
                      <Button
                        className="btn solid btn-small mr-2 btn-danger"
                        onClick={() => this.removeSelected()}>
                        <i className="fas fa-trash mr-2" />
                        <span>Remove</span>
                      </Button>
                    )}
                    {fields_dragged && (
                      <Button
                        className="btn solid btn-small btn-success"
                        onClick={() => this.saveColumns()}>
                        <i className="fas fa-save mr-2" />
                        <span>Save</span>
                      </Button>
                    )}
                  </div>
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th width="2%">
                          <input
                            type="checkbox"
                            onChange={e => {
                              this.toggleAllSelect(e);
                            }}
                            value="all"
                          />
                        </th>
                        <th width="15%">Title</th>
                        <th width="50%">Value</th>
                        <th width="10%">Action</th>
                      </tr>
                    </thead>

                    {Object.entries(columns).length === 0 && (
                      <tbody>
                        <tr>
                          <td colSpan="4" className="text-center">
                            No columns added.{' '}
                            <a
                              href="#"
                              onClick={() => {
                                this.toggleModal('add_column_modal', true);
                              }}>
                              Add Column
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    )}
                    {Object.entries(columns).length > 0 && (
                      <SortableContainer
                        onSortEnd={this.onSortEnd}
                        useDragHandle>
                        {columns.map((column, index) => {
                          return (
                            <SortableItem
                              key={`row-${index}`}
                              index={index}
                              columnIndex={index}
                              column={column}
                              setState={newState => {
                                this.setState(newState);
                              }}
                              toggleSelectMultiple={(e, index) => {
                                this.toggleSelectMultiple(e, index);
                              }}
                              removeColumn={index => {
                                // console.log("Remove Column:", index);
                                this.removeColumn(index);
                              }}
                            />
                          );
                        })}
                      </SortableContainer>
                    )}
                  </table>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
        {add_column_modal && (
          <AddColumnModal
            loading={loading}
            showModal={add_column_modal}
            toggleModal={(modal, state) => this.toggleModal(modal, state)}
            addNewColumn={newColumn => this.saveColumns(newColumn)}
            fields={fields}
          />
        )}
        {edit_column_modal && (
          <EditColumnModal
            loading={loading}
            showModal={edit_column_modal}
            column={columns[editColumnIndex]}
            editColumnIndex={editColumnIndex}
            toggleModal={(modal, state) => this.toggleModal(modal, state)}
            updateColumn={(newColumn, index) =>
              this.updateColumns(newColumn, index)
            }
            fields={fields}
          />
        )}
        {preview_csv_modal && (
          <PreviewCsvModal
            showModal={preview_csv_modal}
            columns={columns}
            list_id={listData.id}
            toggleModal={(modal, state) => this.toggleModal(modal, state)}
          />
        )}
        {download_preparing_modal && (
          <PreparingDownload show={download_preparing_modal} currentLang="en" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  listData: state.singleExportList,
});

export default connect(mapStateProps, {
  save_export_columns,
  get_export_columns,
})(ExportProductsSettings);
