import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Form, Image } from "react-bootstrap";

import logoUrl from "../Assets/images/logo.png";
import { logout } from "../Actions/auth";
import { setHomeLanguage } from "../Actions/HomePageActions";
import { connect } from "react-redux";

import { home_lable } from "../utils/language_untils";

class SideBarMenu extends Component {
    state = {
        classState: "",
        currentLang: "",
    };

    UNSAFE_componentWillMount = () => {
        const { currentLang } = this.props;

        const windowWidth = window.innerWidth;
        const classState = windowWidth < 768 ? "hide" : "";

        this.setState({
            classState,
            currentLang,
        });
    };

    UNSAFE_componentWillReceiveProps = (nextprops) => {
        const { currentLang } = nextprops;
        this.setState({
            currentLang,
        });
    };

    setLanguage = (lang) => {
        this.props.setHomeLanguage(lang);
    };

    toggleMainMenu = () => {
        const sidebarNavigation = document.getElementById("sidebar-menu");
        const contentWrapper = document.getElementById("content-wrapper");

        if (!sidebarNavigation.classList.contains("hide")) sidebarNavigation.classList.add("hide");
        else sidebarNavigation.classList.remove("hide");

        if (!contentWrapper.classList.contains("open")) contentWrapper.classList.add("open");
        else contentWrapper.classList.remove("open");
    };

    render() {
        const { classState, currentLang } = this.state;
        return (
            <React.Fragment>
                <div className="site-header ">
                    <div className="site-logo">
                        <Link to="/">
                            <Image src={logoUrl} alt="Stadler Form" />
                        </Link>
                        <div className="hamberger asLink" onClick={() => this.toggleMainMenu()}>
                            <span />
                            <span className="bar-2" />
                            <span className="bar-3" />
                        </div>
                    </div>
                </div>

                <div id="sidebar-menu" className={`front-sidebar-menu ${classState}`}>
                    <div className="language_pref">
                        <i className="fas fa-globe-americas" />
                        <Form.Control
                            as="select"
                            onChange={(e) => {
                                this.setLanguage(e.target.value);
                            }}
                            value={currentLang}
                        >
                            <option value="en">English</option>
                            <option value="de">Deutsch</option>
                            <option value="fr">Français</option>
                            <option value="it">Italiano</option>
                        </Form.Control>
                    </div>

                    <ul>
                        {this.props.isAuthenticated && (
                            <li>
                                <Link to="/admin">
                                    <i className="fas fa-columns" /> Dashboard
                                </Link>
                            </li>
                        )}

                        <li>
                            <NavLink exact to="/">
                                <i className="fas fa-cog" /> {home_lable.heading[currentLang]}
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink exact to="/faq">
                                <i className="fas fa-question-circle" />{" "}
                                {home_lable.faq[currentLang]}
                            </NavLink>
                        </li> */}
                        {/* {!this.props.isAuthenticated && (
                            <li>
                                <Link to="/login">
                                    <i className="fas fa-lock" /> Login
                                </Link>
                            </li>
                        )} */}
                        {this.props.isAuthenticated && (
                            <li className="asLink" onClick={() => this.props.logout()}>
                                <i className="fas fa-sign-out-alt" /> Logout
                            </li>
                        )}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateProp = (state) => ({
    isAuthenticated: !!state.user.access_token,
    usertype: state.user.user_type,
    initials: state.user.initials,
    currentLang: state.languagePref,
});

export default connect(mapStateProp, { logout, setHomeLanguage })(SideBarMenu);
