import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AdminRoutes = ({
    isAuthenticated,
    currentUser,
    component: Component,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) => {
            if (isAuthenticated && currentUser.user_type === "Administrator") {
                return <Component {...props} />;
            }
            return <Redirect to="/login" />;
        }}
    />
);

const mapStateProp = (state) => ({
    isAuthenticated: !!state.user.access_token,
    currentUser: state.user,
});

AdminRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
};

export default connect(mapStateProp)(AdminRoutes);
