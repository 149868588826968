import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import Header from "./DashbordComponent/Header";
import SidebarMenu from "./DashbordComponent/SidebarMenu";
import WelcomePage from "./DashbordComponent/pages/WelcomePage";
import UsersPage from "./DashbordComponent/pages/UsersPages";
import Products from "./DashbordComponent/pages/Products";
import ProductCates from "./DashbordComponent/pages/ProductCates";
import ProductFamilie from "./DashbordComponent/pages/ProductFamilie";
import ExportList from "./DashbordComponent/pages/ExportList";
import ManageExportListColumns from "./DashbordComponent/pages/ManageExportListColumns";
import SettingsPage from "./DashbordComponent/pages/SettingsPage";
// import MailsPage from "./DashbordComponent/pages/MailsPage";
// import AllOrders from "./DashbordComponent/pages/AllOrders";
// import SearchPage from "./DashbordComponent/pages/SearchPage";

class DashboardPage extends Component {
    state = {
        sidebarMenu: "",
    };
    UNSAFE_componentDidMount = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 768) {
            this.setState({ sidebarMenu: "open" });
        } else {
            this.setState({ sidebarMenu: "" });
        }
    };
    render() {
        const { location } = this.props;
        const { sidebarMenu } = this.state;
        return (
            <div className={`app-wrapper ${sidebarMenu}`}>
                <SidebarMenu location={location} />
                <div className="content-wrapper">
                    <Header />
                    <div className="content">
                        <Route path="/admin" exact component={Products} />
                        <Route path="/admin/users/" exact component={UsersPage} />
                        <Route path="/admin/products/" exact component={Products} />
                        <Route path="/admin/products/page/:page" exact component={Products} />
                        <Route path="/admin/categories" exact component={ProductCates} />
                        <Route path="/admin/familie" exact component={ProductFamilie} />
                        <Route path="/admin/export-lists" exact component={ExportList} />
                        <Route
                            path="/admin/export-lists/manage-columns/:id"
                            exact
                            component={ManageExportListColumns}
                        />
                        <Route path="/admin/settings" exact component={SettingsPage} />
                        {/* <Route path="/admin/projects/" exact component={ProjectsPage} />
                        <Route path="/admin/projects/:id/" exact component={ProjectsPage} />
                        <Route path="/admin/settings/" exact component={SettingsPage} />
                        <Route path="/admin/notification-mails/" exact component={MailsPage} />
                        <Route path="/admin/purchase-orders/" exact component={AllOrders} />
                        <Route path="/admin/search/" exact component={SearchPage} /> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardPage;
