import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const GuestRouter = ({ isAuthenticated, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={ props =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const mapStateProp = state => ({
  isAuthenticated: !!state.user.accessToken
});

GuestRouter.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.shape({}).isRequired
};

export default connect(mapStateProp)(GuestRouter);
