import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Button} from 'react-bootstrap';
import Validator from 'validator';

import AlertDismissible from '../../../Elements/AlertDismissible';
import {appendFormData} from '../../../../utils/commonFunctions';

class AddUserModal extends Component {
  state = {
    data: {},
    errors: {},
    loading: false,
    validated: false,
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
  };
  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

    if (e.target.id === 'usertype') {
      if (e.target.id === 'usertype' && e.target.value === '') {
        document.getElementById('usertype').classList.add('invalid');
      } else {
        document.getElementById('usertype').classList.remove('invalid');
      }
    }
  };

  onSubmit = () => {
    const {data} = this.state;
    this.setState({loading: true});
    const errors = this.validateData(data);

    // console.log(errors);

    if (Object.entries(errors).length === 0) {
      const formData = appendFormData(data);

      this.props
        .adminAddUser(formData)
        .then(res => {
          // console.log(res);
          if (res.type === 'success') {
            this.setState({
              data: {
                name: '',
                email: '',
                password: '',
                user_type: '',
              },
              errors: {},
              alertData: {
                show: true,
                variant: 'success',
                heading: '',
                message: res.message,
              },
              loading: false,
            });
          } else if (res.type === 'error') {
            this.setState({
              alertData: {
                show: true,
                variant: 'danger',
                heading: 'Somethig went wrong!',
                message: res.message,
              },
              loading: false,
            });
          }
        })
        .catch(err => {});
    } else {
      this.setState({errors, loading: false, validated: true});
    }
  };

  validateData = data => {
    const errors = {};

    if (!data.name) errors.name = 'Please Enter Name.';
    if (!data.email) errors.email = 'Please Enter Email.';
    if (!data.password) errors.password = 'Please Enter Password.';
    // if (!data.company_id) errors.company_id = "Please Select Company.";
    else if (!Validator.isEmail(data.email))
      errors.email = 'Please enter valid Email.';

    if (!data.user_type) {
      document.getElementById('user_type').classList.add('invalid');
      errors.user_type = 'Please Select User type.';
    }

    return errors;
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        show: false,
      },
    });
  };

  render() {
    const {errors, validated, loading, data, alertData} = this.state;
    return (
      <Modal
        show={this.props.showModal}
        onHide={() => this.props.toggleModal('addUserModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertData.show && (
            <AlertDismissible
              show={alertData.show}
              variant={alertData.variant}
              heading={alertData.heading}
              message={alertData.message}
              closeAlert={this.closeAlert}
            />
          )}
          <Form validated={validated} onSubmit={this.onSubmit}>
            <Form.Group className="with-icon">
              <i className="fas fa-user" />
              <Form.Control
                type="text"
                name="name"
                value={data.name}
                required
                placeholder="Name"
                onChange={e => this.onChange(e)}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="with-icon">
              <i className="far fa-envelope" />
              <Form.Control
                type="email"
                name="email"
                value={data.email}
                required
                placeholder="Email"
                onChange={e => this.onChange(e)}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="with-icon">
              <i className="far fa-eye" />
              <Form.Control
                type="password"
                name="password"
                value={data.password}
                required
                placeholder="Password"
                onChange={e => this.onChange(e)}
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* <Form.Group className="with-icon">
                            <i className="fas fa-user-tag" />
                            <Form.Control
                                id="company_id"
                                name="company_id"
                                as="select"
                                onChange={(e) => this.onChange(e)}
                            >
                                <option value="">Company</option>
                                <option value="1">Company 1</option>
                                <option value="2">Company 2</option>
                            </Form.Control>
                            {errors.company_id && (
                                <Form.Control.Feedback type="invalid">
                                    {errors.company_id}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group> */}

            <Form.Group className="with-icon">
              <i className="fas fa-user-tag" />
              <Form.Control
                id="user_type"
                name="user_type"
                as="select"
                onChange={e => this.onChange(e)}>
                <option value="">User Type</option>
                <option value="Normal">Normal</option>
                <option value="Administrator">Admin</option>
              </Form.Control>
              {errors.user_type && (
                <Form.Control.Feedback type="invalid">
                  {errors.user_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-medium" onClick={() => this.onSubmit()}>
            <span>Add</span>
          </Button>
          {loading && <i className="fas fa-spinner fa-pulse loadingIcon" />}
        </Modal.Footer>
      </Modal>
    );
  }
}

AddUserModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  adminAddUser: PropTypes.func.isRequired,
};

export default AddUserModal;
