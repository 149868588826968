import React, {Component} from 'react';

import {connect} from 'react-redux';
import {Card, Image, Form} from 'react-bootstrap';

import AlertDismissible from '../../../Elements/AlertDismissible';
import {
  adminGetCategories,
  adminEditCategory,
} from '../../../../Actions/AdminActions';

import defaultImg from '../../../../Assets/images/default-img.jpg';

class ProductCates extends Component {
  state = {
    categories: [],
    errors: [],
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
    pagginInfo: [],
  };

  UNSAFE_componentWillMount = () => {
    this.get_products();
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {categories} = nextProps;

    if (Object.entries(categories).length !== 0) {
      categories.map((category, index) => {
        if (typeof category.status !== 'boolean') {
          categories[index]['status'] = category.status === '1' ? true : false;
        }
      });
    }

    this.setState({
      categories,
    });
  };

  get_products = (page = 1) => {
    this.props.adminGetCategories(page).then(res => {
      if (res.type === 'success') {
        this.setState({pagginInfo: res.pagging_info});
      } else if (res.type === 'error') {
      }
    });
  };

  toggleModal = (modal, value) => {
    switch (modal) {
      case 'editProductModal':
        this.setState({editProductModal: value});
        break;
      default:
        break;
    }
    return '';
  };

  updateCategoryStatus = (index, categoryId, e) => {
    const {categories} = this.state;
    categories[index]['status'] = e.target.checked;
    this.setState(
      {
        categories,
      },
      () => {
        const {categories} = this.state;

        const status = categories[index].status ? 1 : 0;
        const cateDatas = new FormData();
        cateDatas.append('category_id', categoryId);
        cateDatas.append('status', status);

        this.props.adminEditCategory(cateDatas).then(res => {
          if (res.type === 'success') {
            this.setState({
              alertData: {
                heading: '',
                message: res.message,
                show: true,
                variant: 'success',
              },
            });
          } else {
            this.setState({
              alertData: {
                heading: '',
                message: res.message,
                show: true,
                variant: 'danger',
              },
            });
          }
        });
      },
    );
  };

  renderProductItem = (category, index, startIndex) => {
    startIndex = startIndex + index;
    const catIMg = category.category_image[0]
      ? category.category_image[0]
      : defaultImg;
    return (
      <tr>
        <td>{startIndex}</td>
        <td>
          <Image src={catIMg} alt="" className="category-img" />
        </td>
        <td>{category.category_reference}</td>
        <td>{category.category_name_translations.category_name_en}</td>
        <td>{category.category_name_translations.category_name_de}</td>
        <td>{category.category_name_translations.category_name_fr}</td>
        <td>{category.category_name_translations.category_name_it}</td>
        <td className="text-center">
          <Form.Check
            type="switch"
            id={`category_${category.id}`}
            name={`category_${category.id}`}
            value={category.id}
            onChange={e => {
              this.updateCategoryStatus(index, category.id, e);
            }}
            checked={category.status ? 'checked' : ''}
            label=""
          />
        </td>
        {/* <td className="text-center">
                    <span>
                        <i className="fas fa-eye" />
                    </span>
                </td> */}
      </tr>
    );
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        heading: '',
        message: '',
        show: false,
        variant: '',
      },
    });
  };

  render() {
    const {alertData, categories, pagginInfo} = this.state;
    const nextClass = pagginInfo.next === 'disable' ? 'disabled' : '';
    const prvClass = pagginInfo.previous === 'disable' ? 'disabled' : '';

    return (
      <React.Fragment>
        <div className="pageHeading d-flex">
          <h2>Categories</h2>
          <div className="extras align-self-center ml-auto"></div>
        </div>
        {alertData.show && (
          <AlertDismissible
            show={alertData.show}
            variant={alertData.variant}
            heading={alertData.heading}
            message={alertData.message}
            closeAlert={this.closeAlert}
          />
        )}
        <Card>
          <Card.Body>
            <div className="pagging-info">
              {pagginInfo.total_item} items
              <span
                className={`nav-btn prv ${prvClass}`}
                onClick={() => {
                  if (pagginInfo.previous !== 'disable')
                    this.get_products(pagginInfo.previous);
                }}>
                ‹
              </span>
              <span className="page-text">
                Current Page {pagginInfo.current} of {pagginInfo.total_pages}
              </span>
              <span
                className={`nav-btn next ${nextClass}`}
                onClick={() => {
                  if (pagginInfo.next !== 'disable')
                    this.get_products(pagginInfo.next);
                }}>
                ›
              </span>
            </div>
            <div className="data-listing">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th width="1%" />
                    <th width="10%">Category Image</th>
                    <th width="15%">Category Reference</th>
                    <th width="15%">Category Name EN</th>
                    <th width="15%">Category Name DE</th>
                    <th width="15%">Category Name FR</th>
                    <th width="15%">Category Name IT</th>
                    <th width="8%" className="text-center">
                      Status
                    </th>
                    {/* <th width="4%" className="text-center">
                                            Actions
                                        </th> */}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(categories).length !== 0 &&
                    categories.map((category, index) =>
                      this.renderProductItem(
                        category,
                        index,
                        pagginInfo.indexStart,
                      ),
                    )}
                  {Object.entries(categories).length === 0 && (
                    <tr>
                      <td colSpan="8">
                        <p className="text-center">No Categories Available.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagging-info">
              {pagginInfo.total_item} items
              <span
                className={`nav-btn prv ${prvClass}`}
                onClick={() => {
                  if (pagginInfo.previous !== 'disable')
                    this.get_products(pagginInfo.previous);
                }}>
                ‹
              </span>
              <span className="page-text">
                Current Page {pagginInfo.current} of {pagginInfo.total_pages}
              </span>
              <span
                className={`nav-btn next ${nextClass}`}
                onClick={() => {
                  if (pagginInfo.next !== 'disable')
                    this.get_products(pagginInfo.next);
                }}>
                ›
              </span>
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  categories: state.adminCategoryList,
});

export default connect(mapStateProps, {
  adminGetCategories,
  adminEditCategory,
})(ProductCates);
