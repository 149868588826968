import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";

class WelcomePage extends Component {
    state = {
        recent: {
            loading: true,
            recentProjects: [],
        },
        mostVoted: {
            loading: true,
            mostVotedProjects: [],
        },
        winners: {
            loading: true,
            recentWinner: [],
        },
        lightBox: {
            show: false,
            images: [],
        },
    };

    // editProject = projectId => {
    // 	console.log(this.props);
    // 	// this.props.history.location.push("/admin/projects");
    // };

    projectTemplate = (project) => {
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const images = [`${serverUrl}/${project.screenshot}`];
        if (project.afterscreenshot) images.push(`${serverUrl}/${project.afterscreenshot}`);
        return (
            <div className="projects">
                <Row>
                    <Col xs={4} lg={3} md={4}>
                        <span className="asLink" onClick={() => this.openLightBox(images)}>
                            <div
                                className="project-img"
                                style={{
                                    backgroundImage: `url(${serverUrl}/${project.screenshot})`,
                                }}
                            />
                        </span>
                    </Col>
                    <Col>
                        <h2>
                            <Link to={`/admin/projects/${project._id}`}>{project.title}</Link>
                        </h2>
                    </Col>
                </Row>
            </div>
        );
    };

    openLightBox = (images) => {
        const body = document.body;
        body.classList.add("hidden");

        this.setState({
            lightBox: { show: true, images },
        });
    };
    closeLightBox = () => {
        const body = document.body;
        body.classList.remove("hidden");
        this.setState({
            lightBox: {
                show: false,
                images: "",
            },
        });
    };

    render() {
        const { recent, mostVoted, winners, lightBox } = this.state;
        return (
            <div className="dashboard">
                <div className="pageHeading">
                    <h2>Dashboard Page</h2>
                </div>
            </div>
        );
    }
}

WelcomePage.propTypes = {
    // getRecentProjects: PropTypes.func.isRequired,
    // getMostVotedProjects: PropTypes.func.isRequired,
    // getRecentWinner: PropTypes.func.isRequired,
};

export default connect(null, {})(WelcomePage);
