import React, { Component } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import AlertDismissible from "../../../Elements/AlertDismissible";

class ProductModal extends Component {
    state = {
        alertData: {
            heading: "",
            message: "",
            show: false,
            variant: "",
        },
    };

    componentWillMount = () => {};

    closeAlert = () => {
        this.setState({
            alertData: {
                show: false,
            },
        });
    };

    render() {
        const { alertData } = this.state;
        const { product } = this.props;
        const { product_fields } = this.props.product;

        // console.log("Product Details :", product, product_fields);

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + "." + mm + "." + yyyy;

        const prStatus = product.status ? "Ist online" : "Ist offline";

        const kapazitat_max =
            product_fields && product_fields.kapazitat_max
                ? product_fields.kapazitat_max
                : "-";
        const raumgrosse_m2 =
            product_fields && product_fields.raumgrosse_m2
                ? `${product_fields.raumgrosse_m2} m² /${product_fields.raumgrosse_m3} m³`
                : "-";
        const system_de =
            product_fields && product_fields.system_en
                ? product_fields.system_en
                : "-";
        const leistungsstufen =
            product_fields && product_fields.leistungsstufen
                ? product_fields.leistungsstufen
                : "-";
        const leistungsaufnahme_w =
            product_fields && product_fields.leistungsaufnahme_w
                ? product_fields.leistungsaufnahme_w
                : "-";
        const abmessungen =
            product_fields &&
            product_fields.lange &&
            product_fields.hohe &&
            product_fields.tiefe
                ? `${product_fields.lange} x ${product_fields.hohe} x ${product_fields.tiefe} mm`
                : "-";
        const gewicht_brutto =
            product_fields && product_fields.gewicht_brutto
                ? `${product_fields.gewicht_brutto} kg`
                : "-";
        const wassertank_kapazitat =
            product_fields && product_fields.wassertank_kapazitat
                ? product_fields.wassertank_kapazitat
                : "-";
        const gerauschpegel =
            product_fields && product_fields.gerauschpegel
                ? product_fields.gerauschpegel
                : "-";
        const zubehor_inklusive_de =
            product_fields && product_fields.zubehor_inklusive_en
                ? product_fields.zubehor_inklusive_en
                : "-";
        const zubehor_optional_de =
            product_fields && product_fields.zubehor_optional_en
                ? product_fields.zubehor_optional_en
                : "-";
        const uvp_de =
            product_fields && product_fields.uvp_en
                ? product_fields.uvp_en
                : "-";
        // 179 CHF / 149 € / 169 USD

        const color =
            product_fields && product_fields.farbe_en
                ? product_fields.farbe_en[0].toUpperCase() +
                  product_fields.farbe_en.slice(1)
                : "";

        return (
            <Modal
                className="max"
                show={this.props.showModal}
                onHide={() =>
                    this.props.toggleModal("showProductModal", false, "")
                }
            >
                <Modal.Header closeButton>
                    <Modal.Title>&nbsp;</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {alertData.show && (
                        <AlertDismissible
                            show={alertData.show}
                            variant={alertData.variant}
                            heading={alertData.heading}
                            message={alertData.message}
                            closeAlert={this.closeAlert}
                        />
                    )}
                    {product_fields && (
                        <div className="product-wrapper">
                            <h2>
                                Stadler Form Produktebeschrieb für
                                {` ${product.product_name} - ${color}`}
                            </h2>

                            <table className="product-name">
                                <tr>
                                    <th>Produkt</th>
                                    <th>Datum</th>
                                    <th>Veröffentlichung</th>
                                </tr>
                                <tr>
                                    <td>{product.product_name}</td>
                                    <td>{today}</td>
                                    <td>{prStatus}</td>
                                </tr>
                            </table>
                            {product_fields.slogan_en && (
                                <div className="product-section">
                                    <h3>Slogan</h3>
                                    {
                                        <p>
                                            <b>{product_fields.slogan_en}</b>
                                        </p>
                                    }
                                </div>
                            )}
                            {product_fields.feature_1_en !== "" && (
                                <div className="product-section">
                                    <h3>3 wichtigste Features</h3>
                                    <p>
                                        <ul>
                                            {product_fields.feature_1_en && (
                                                <li>
                                                    {
                                                        product_fields.feature_1_en
                                                    }
                                                </li>
                                            )}
                                            {product_fields.feature_2_en && (
                                                <li>
                                                    {
                                                        product_fields.feature_2_en
                                                    }
                                                </li>
                                            )}
                                            {product_fields.feature_3_en && (
                                                <li>
                                                    {
                                                        product_fields.feature_3_en
                                                    }
                                                </li>
                                            )}
                                        </ul>
                                    </p>
                                </div>
                            )}
                            {product.product_description_en &&
                                product.product_description_en !==
                                    "undefined" &&
                                product.product_description_en !== "" && (
                                    <div className="product-section">
                                        <h3>Beschreibungstext</h3>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: product.product_description_de,
                                            }}
                                        />
                                    </div>
                                )}

                            {product_fields &&
                                product_fields.sf_text_1_de !==
                                    "undefined" &&
                                    product_fields.sf_text_1_de !== "" && (
                                    <div className="product-section">
                                        <h3>Zusätzlicher Inhalt</h3>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: product_fields.sf_text_1_de,
                                            }}
                                        />
                                    </div>
                                )}
                                
                            <div className="product-section product-details-section">
                                <h3>Technische Daten</h3>
                                <table className="no-border">
                                    {system_de && (
                                        <tr>
                                            <td width="150px">System: </td>
                                            <td>{system_de}</td>
                                        </tr>
                                    )}
                                    {kapazitat_max && (
                                        <tr>
                                            <td>Kapazität (bis): </td>
                                            <td>{kapazitat_max}</td>
                                        </tr>
                                    )}
                                    {raumgrosse_m2 && (
                                        <tr>
                                            <td>Raumgrösse (bis): </td>
                                            <td>{raumgrosse_m2}</td>
                                        </tr>
                                    )}
                                    {leistungsstufen && (
                                        <tr>
                                            <td>Leistungsstufen: </td>
                                            <td>{leistungsstufen}</td>
                                        </tr>
                                    )}
                                    {leistungsaufnahme_w && (
                                        <tr>
                                            <td>Leistungsaufnahme: </td>
                                            <td>{leistungsaufnahme_w}</td>
                                        </tr>
                                    )}
                                    {abmessungen && (
                                        <tr>
                                            <td>Abmessungen: </td>
                                            <td>{abmessungen}</td>
                                        </tr>
                                    )}
                                    {gewicht_brutto && (
                                        <tr>
                                            <td>Gewicht: </td>
                                            <td>{gewicht_brutto}</td>
                                        </tr>
                                    )}
                                    {wassertank_kapazitat && (
                                        <tr>
                                            <td>Wassertank Kapazität: </td>
                                            <td>{wassertank_kapazitat}</td>
                                        </tr>
                                    )}
                                    {gerauschpegel && (
                                        <tr>
                                            <td>Geräuschpegel: </td>
                                            <td>{gerauschpegel}</td>
                                        </tr>
                                    )}
                                    {zubehor_inklusive_de && (
                                        <tr>
                                            <td>Zubehör inklusive: </td>
                                            <td>{zubehor_inklusive_de}</td>
                                        </tr>
                                    )}
                                    {zubehor_optional_de && (
                                        <tr>
                                            <td>Zubehör optional: </td>
                                            <td>{zubehor_optional_de}</td>
                                        </tr>
                                    )}
                                    {uvp_de && (
                                        <tr>
                                            <td>UVP: </td>
                                            <td>{uvp_de}</td>
                                        </tr>
                                    )}
                                </table>
                                <h4>Artikelnummer und EAN-Code:</h4>
                                <table className="border-inside">
                                    <tr>
                                        <th width="33%">{`${color}`}</th>
                                        <th width="33%">&nbsp;</th>
                                        <th width="33%">&nbsp;</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {`${product.artikelnummer} `} /
                                            {`${product.ean_nummer}`}
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </table>
                            </div>
                            {product_fields.yt_link_produktvideo_en && (
                                <div className="product-section">
                                    <h3>
                                        Weitere Angaben (Fotos, YouTube Video,
                                        Bedienungsanleitung, Datenblatt)
                                    </h3>
                                    {product_fields.yt_link_produktvideo_en && (
                                        <p>
                                            YouTube Video Deutsch:{" "}
                                            <a
                                                href={
                                                    product_fields.yt_link_produktvideo_en
                                                }
                                                target="_blank"
                                            >
                                                {
                                                    product_fields.yt_link_produktvideo_en
                                                }
                                            </a>
                                        </p>
                                    )}
                                    {product_fields.yt_link_unboxing_features_en && (
                                        <p>
                                            YouTube Video Unboxing:{" "}
                                            <a
                                                href={
                                                    product_fields.yt_link_unboxing_features_en
                                                }
                                                target="_blank"
                                            >
                                                {
                                                    product_fields.yt_link_unboxing_features_en
                                                }
                                            </a>
                                        </p>
                                    )}
                                    {product_fields.yt_link_reinigung_en && (
                                        <p>
                                            YouTube Video “How to” Reinigung
                                            Oskar:{" "}
                                            <a
                                                href={
                                                    product_fields.yt_link_reinigung_en
                                                }
                                                target="_blank"
                                            >
                                                {
                                                    product_fields.yt_link_reinigung_en
                                                }
                                            </a>
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }
}

export default ProductModal;
