import { USER_LOGGED_IN, USER_SIGN_UP, USER_LOGOUT } from "../utils/types";
import api from "../utils/api";
import jwtDecode from "jwt-decode";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";

export const userLoggedIn = (user) => ({
    type: USER_LOGGED_IN,
    user,
});
export const userSignUp = (user) => ({
    type: USER_SIGN_UP,
    user,
});
export const userLogout = () => ({
    type: USER_LOGOUT,
});

export const login = (creadentials) => (dispatch) =>
    api.user.login(creadentials).then((user) => {
        if (user.type !== "error") {
            localStorage.setItem("pim_access_token", user.access_token);
            setAuthorizationHeader(user.access_token);

            const decoded = jwtDecode(user.access_token);
            const userData = {
                uid: decoded.user_id,
                email: decoded.email,
                user_type: decoded.user_type,
                initials: decoded.initials,
                access_token: user.access_token,
            };

            dispatch(userLoggedIn(userData));
        }
        return user;
    });

export const signup = (data) => (dispatch) =>
    api.user.signup(data).then((user) => {
        localStorage.setItem("pim_access_token", user.access_token);
        setAuthorizationHeader(user.access_token);
        dispatch(userSignUp(user));
    });

export const ResetRequest = (data) => () => api.user.resetRequest(data);
export const verifyResetToken = (data) => () => api.user.verifyResetToken(data);
export const updatePassword = (data) => () => api.user.updatePassword(data);
export const ConfirmAccountAction = (token) => () =>
    api.user.confirmMyAccount(token);

export const logout = () => (dispatch) => {
    localStorage.removeItem("pim_access_token");
    setAuthorizationHeader();
    dispatch(userLogout());
};
