import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import PropTypes from "prop-types";

import logoUrl from "../../../Assets/images/logo.png";
import { logout } from "../../../Actions/auth";

class SidebarMenu extends Component {
    logout = () => {
        this.props.logout();
    };

    slideMenu = () => {
        const wrapper = document.getElementsByClassName("app-wrapper");

        if (wrapper[0].className.indexOf("open") > 0) {
            wrapper[0].classList.remove("open");
        } else {
            wrapper[0].classList.add("open");
        }
    };

    menuItems = [
        // {
        //   icon: "",
        //   title: "",
        //   link: "",
        //   submenu: {
        //     item: {
        //       title: "",
        //       link: ""
        //     }
        //   }
        // },
        // {        //
        //     icon: "fas fa-columns",
        //     title: "Dashboard",
        //     link: "/admin",
        //     submenu: [
        //         {        //
        //             title: "Visit Site",
        //             link: "/",
        //         },
        //     ],
        // },
        {
            icon: "fas fa-sitemap",
            title: "Visit Site",
            link: "/",
            submenu: false,
        },
        {
            icon: "fas fa-store",
            title: "Products",
            link: "/admin/products",
            submenu: false,
        },
        {
            icon: "fas fa-tags",
            title: "Categories",
            link: "/admin/categories",
            submenu: false,
        },
        {
            //
            icon: "fas fa-user-tag",
            title: "Familie",
            link: "/admin/familie",
            submenu: false,
        },
        {
            icon: "fas fa-users",
            title: "Users",
            link: "/admin/users",
            submenu: false,
        },
        {
            icon: "fas fa-cloud-upload-alt",
            title: "Export List",
            link: "/admin/export-lists",
            submenu: false,
        },
        {
            icon: "fas fa-sliders-h",
            title: "Settings",
            link: "/admin/settings",
        },
    ];

    listMenuItems = (menuItems) => {
        const activePage = this.props.location.pathname;

        return menuItems.map((item, index) => {
            let ClassName = activePage === item.link ? "active" : "";

            if (item.submenu) {
                item.submenu.map((subitem) => {
                    if (activePage === subitem.link) ClassName = "active";
                    return null;
                });
            }

            return (
                <li key={`parent_menu_${index}`} className={ClassName}>
                    <NavLink exact to={item.link}>
                        <i className={`menu-icon ${item.icon}`} />
                        <span className="menu-title">{item.title}</span>
                    </NavLink>
                    {item.submenu && (
                        <React.Fragment>
                            <ul className="sub-menu">
                                {item.submenu.map((subitem, index) => {
                                    const subClassActive = activePage === subitem.link ? "active" : "";
                                    return (
                                        <li key={`sub_menu_${index}`} className={subClassActive}>
                                            <NavLink exact to={subitem.link}>
                                                {subitem.title}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </React.Fragment>
                    )}

                    {item.submenu && (
                        <span
                            className="down-arrow"
                            onClick={(e) => {
                                this.toggleSubMenu(e);
                            }}
                        >
                            <i className="fas fa-angle-right" />
                        </span>
                    )}
                </li>
            );
        });
    };

    toggleSubMenu = (e) => {
        const parentLi = e.currentTarget.parentNode.className;
        if (parentLi === "") {
            e.currentTarget.parentNode.classList.add("active");
        } else {
            e.currentTarget.parentNode.classList.remove("active");
        }
    };

    render() {
        return (
            <div className="sidebar-wrapper">
                <div className="logo-wrapper">
                    <Link to="/admin">
                        <Image src={logoUrl} alt="Stadler Form" />
                    </Link>
                    <div className="menu-icon" onClick={() => this.slideMenu()}>
                        <span className="bars" />
                        <span className="bars" />
                        <span className="bars" />
                    </div>
                </div>
                <div className="sidebar-menu">
                    <ul>
                        {this.listMenuItems(this.menuItems)}
                        <li>
                            <span className="link" onClick={() => this.logout()}>
                                <i className="menu-icon fas fa-sign-out-alt" />
                                <span className="menu-title">Logout</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

SidebarMenu.propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default connect(null, { logout })(SidebarMenu);
