import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Button} from 'react-bootstrap';
import Validator from 'validator';

import AlertDismissible from '../../../Elements/AlertDismissible';
import {appendFormData} from '../../../../utils/commonFunctions';

class EditUserModal extends Component {
  state = {
    udata: {
      newpassword: '',
    },
    errors: {},
    loading: false,
    validated: false,
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
  };

  UNSAFE_componentWillMount() {
    const {status} = this.props.user;
    const ustatus = status === '1' ? true : false;

    this.setState({
      udata: {
        ...this.props.user,
        status: ustatus,
      },
    });
  }

  onChange = e => {
    this.setState({
      udata: {
        ...this.state.udata,
        [e.target.name]: e.target.value,
      },
    });

    if (e.target.id === 'user_type') {
      if (e.target.id === 'user_type' && e.target.value === '') {
        document.getElementById('user_type').classList.add('invalid');
      } else {
        document.getElementById('user_type').classList.remove('invalid');
      }
    }
  };

  handle_submit = () => {
    const {udata, loading} = this.state;
    const errors = this.validateData(udata);

    if (Object.entries(errors).length === 0 && loading === false) {
      this.setState({loading: true});

      const status = udata.status ? '1' : '0';
      const password = udata.newpassword ? udata.newpassword : '';

      const formData = appendFormData(udata);

      formData.append('password', password);
      formData.append('status', status);

      this.props
        .adminEditUser(formData)
        .then(res => {
          if (res.type === 'success') {
            this.setState({
              alertData: {
                show: true,
                variant: 'success',
                heading: '',
                message: res.message,
              },
              loading: false,
            });
          } else if (res.type === 'error') {
            this.setState({
              alertData: {
                show: true,
                variant: 'danger',
                heading: '',
                message: res.message,
              },
              loading: false,
            });
          }
        })
        .catch(err => {});
    } else {
      this.setState({errors, loading: false, validated: true});
    }
  };

  checkboxHandle = e => {
    const userStatus = e.target.value;
    this.setState(
      {
        udata: {
          ...this.state.udata,
          [e.target.name]: e.target.checked,
        },
      },
      () => {
        // console.log("User status :", this.state.udata.status);
      },
    );
  };

  validateData = data => {
    const errors = {};

    if (!data.name) errors.name = 'Please Enter Name.';
    if (!data.email) errors.email = 'Please Enter Email.';
    else if (!Validator.isEmail(data.email))
      errors.email = 'Please enter valid Email.';

    if (!data.user_type) {
      document.getElementById('user_type').classList.add('invalid');
      errors.user_type = 'Please Select User type.';
    }

    return errors;
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        show: false,
      },
    });
  };

  render() {
    const {errors, validated, loading, udata, alertData} = this.state;
    return (
      <Modal
        show={this.props.showModal}
        onHide={() => this.props.toggleModal('editUserModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit New</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alertData.show && (
            <AlertDismissible
              show={alertData.show}
              variant={alertData.variant}
              heading={alertData.heading}
              message={alertData.message}
              closeAlert={this.closeAlert}
            />
          )}
          <Form validated={validated} onSubmit={() => this.handle_submit()}>
            <Form.Group className="with-icon">
              <i className="fas fa-user" />
              <Form.Control
                type="text"
                name="name"
                value={udata.name}
                required
                placeholder="First Name"
                onChange={e => this.onChange(e)}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="with-icon">
              <i className="far fa-envelope" />
              <Form.Control
                type="email"
                name="email"
                value={udata.email}
                required
                placeholder="Your Email"
                onChange={e => this.onChange(e)}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="with-icon">
              <i className="far fa-eye" />
              <Form.Control
                type="password"
                name="newpassword"
                value={udata.newpassword}
                placeholder="Your password"
                onChange={e => this.onChange(e)}
              />
              {errors.newpassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.newpassword}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="with-icon">
              <i className="fas fa-user-tag" />
              <Form.Control
                id="user_type"
                name="user_type"
                as="select"
                onChange={e => this.onChange(e)}>
                <option value="">Select</option>
                <option
                  selected={udata.user_type === 'Normal' ? 'selected' : ''}
                  value="Normal">
                  Normal
                </option>
                <option
                  selected={
                    udata.user_type === 'Administrator' ? 'selected' : ''
                  }
                  value="Administrator">
                  Administrator
                </option>
              </Form.Control>

              {errors.user_type && (
                <Form.Control.Feedback type="invalid">
                  {errors.user_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="user_status" className="mt-15">
              <Form.Check
                custom
                type="switch"
                name="status"
                value="1"
                label={udata.status ? 'Deactivate User' : 'Activate User'}
                checked={udata.status ? 'checked' : ''}
                onChange={e => {
                  this.checkboxHandle(e);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-medium"
            onClick={() => this.handle_submit()}>
            <span>Save</span>
          </Button>
          {loading && <i className="fas fa-spinner fa-pulse loadingIcon" />}
        </Modal.Footer>
      </Modal>
    );
  }
}

EditUserModal.propTypes = {
  user: PropTypes.shape({
    fname: PropTypes.string.isRequired,
    lname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    usertype: PropTypes.string.isRequired,
  }).isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  adminEditUser: PropTypes.func.isRequired,
};

export default EditUserModal;
