import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from "react-bootstrap";

import { getSearchProducts } from "../../Actions/HomePageActions";
import { home_lable } from "../../utils/language_untils";

class SerachBox extends Component {
    constructor(props) {
        super(props);
        this.timeout = 0;
    }

    state = {
        search: "",
        reset: false,
        loading: false,
        products: {},
        currentLang: "en",
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { currentLang, reset } = nextProps;
        const stateD = {
            currentLang,
        };

        if (reset) {
            const searchText = "";
            stateD.search = searchText;
        }

        this.setState(stateD);
    };

    searchProduct = (e) => {
        const search = e.target.value;

        this.props.updateReset(false);

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        //search function
        this.setState(
            {
                search,
            },
            () => {
                this.timeout = setTimeout(() => {
                    const data = new FormData();
                    data.append("search", search);
                    if (search !== "" && search !== " ") {
                        this.setState({ loading: true });
                        this.props
                            .getSearchProducts(data)
                            .then((res) => {
                                if (res.type === "success") {
                                    const { products } = res;

                                    this.setState(
                                        {
                                            loading: false,
                                            products,
                                        },
                                        () => this.props.setProducts(products)
                                    );
                                } else {
                                    this.setState(
                                        {
                                            loading: false,
                                            products: {},
                                        },
                                        () => {
                                            this.props.setProducts({});
                                            // this.props.searchErr({
                                            //     show: true,
                                            //     variant: "danger",
                                            //     message: res.message,
                                            // });
                                        }
                                    );
                                }
                            })
                            .catch((err) => {
                                this.setState(
                                    {
                                        loading: false,
                                        products: {},
                                    },
                                    () => {
                                        this.props.setProducts({});
                                    }
                                );
                            });
                    } else {
                        this.setState(
                            {
                                search: "",
                                loading: false,
                                products: {},
                            },
                            () => {
                                this.props.setProducts({});
                            }
                        );
                    }
                }, 300);
            }
        );
    };

    selectProduct = (id) => {
        this.setState({
            products: {},
        });
        this.props.selectProduct(id);
    };

    render() {
        const { search, loading, products, currentLang } = this.state;

        return (
            <Row>
                <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1" style={{ position: "relative" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Form.Control
                                type="text"
                                name="search"
                                value={search}
                                required
                                placeholder={home_lable.search_placeholder[currentLang]}
                                onChange={(e) => {
                                    this.searchProduct(e);
                                }}
                                autoComplete="off"
                            />
                            {loading && (
                                <span>
                                    <i className="fas fa-spinner fa-pulse loadingIcon" />
                                </span>
                            )}
                        </div>

                        {Object.entries(products).length !== 0 && (
                            <div className="searchResults">
                                <ul>
                                    {products.map((item, index) => {
                                        const product_name =
                                            item.product_fields &&
                                            item.product_fields[`product_name_${currentLang}`] != ""
                                                ? item.product_fields[`product_name_${currentLang}`]
                                                : item.product_name;

                                        return (
                                            <li
                                                key={item.id}
                                                onClick={() => {
                                                    this.selectProduct(index);

                                                    this.setState({ search: product_name });
                                                }}
                                            >
                                                {product_name}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </Form.Group>

                    <h6 className="text-center">{home_lable.or_text[currentLang]}</h6>
                </Col>
            </Row>
        );
    }
}

export default connect(null, { getSearchProducts })(SerachBox);
