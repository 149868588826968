export const home_lable = {
  window_title: {
    de: 'Stadler Form Produkt Datenbank',
    en: 'Stadler Form Product Database',
    fr: 'Base de données de produits Stadler Form',
    it: 'Database de prodotti Stadler Form',
  },
  meta_content: {
    de: 'Diese Datenbank enthält alle relevanten Stadler Form-Produktdaten - Alle technischen Änderungen vorbehalten. - Ihr Stadler Form Team.',
    en: 'This database contains all relevant Stadler Form product data - All technical changes reserved. - Your Stadler Form Team.',
    fr: 'Cette base de données contient toutes les données pertinentes des produits Stadler Form - Sous réserve de toute modification technique. - Votre équipe Stadler Form.',
    it: 'Questa banca dati contiene tutti i dati rilevanti del prodotto "Stadler Form" - Tutte le modifiche tecniche sono riservate. - Il vostro team "Stadler Form".',
  },
  faq: {
    de: 'FAQ',
    en: 'FAQ',
    fr: 'FAQ',
    it: 'FAQ',
  },
  heading: {
    de: 'Produkt Datenbank',
    en: 'Product Database',
    fr: 'Base de données de produits',
    it: 'Database dei prodotti',
  },
  select_category_label: {
    de: 'Produktkategorie',
    en: 'Product Category',
    fr: 'Catégorie de produit',
    it: 'Categoria di prodotto',
  },
  select_category: {
    de: 'Wählen Sie eine Produktkategorie',
    en: 'Select Product Category',
    fr: 'Choisissez une catégorie de produits',
    it: 'Scegli una categoria di prodotto',
  },
  select_family_label: {
    de: 'Familie',
    en: 'Family',
    fr: 'Famille',
    it: 'Famiglia',
  },
  select_family: {
    de: 'Wählen Sie ein Familie',
    en: 'Select a Family',
    fr: 'Choisissez un Famille',
    it: 'Scegli un Famiglia',
  },
  select_product_label: {
    de: 'Produkt',
    en: 'Product',
    fr: 'Produit',
    it: 'Prodotto',
  },
  select_product: {
    de: 'Wählen Sie ein Produkt',
    en: 'Select a product',
    fr: 'Choisissez un produit',
    it: 'Scegli un prodotto',
  },
  chk_pdf: {
    de: 'PDF',
    en: 'PDF',
    fr: 'PDF',
    it: 'PDF',
  },
  chk_images: {
    de: 'BILDER',
    en: 'IMAGES',
    fr: 'IMAGES',
    it: 'Immagini',
  },
  chk_videos: {
    de: 'VIDEOS',
    en: 'VIDEOS',
    fr: 'Vidéos',
    it: 'Video',
  },
  show_certificates: {
    de: 'Zertifikate / Auszeichnungen',
    en: 'Certificates / Awards',
    fr: 'Certificats / Disctinctions',
    it: 'Certificati / premi',
  },
  apply_btn: {
    de: 'Bestätigen',
    en: 'Apply',
    fr: 'Confirmer',
    it: 'Confermare',
  },
  reset_btn: {
    de: 'Zurücksetzen',
    en: 'Reset',
    fr: 'Réinitialiser',
    it: 'Ripristina',
  },
  download_pdf_details: {
    de: 'Produktdetails herunterladen',
    en: 'Download Product Details',
    fr: 'Télécharger les détails du produit',
    it: 'Scarica i dettagli del prodotto',
  },
  download_pdf_manual: {
    de: 'Bedienungsanleitung',
    en: 'Operation Manual',
    fr: 'Mode d’emploi',
    it: 'Istruzioni per l’uso',
  },
  download_pdf_pos_karte: {
    de: 'POS KARTE DE',
    en: 'POS Card EN',
    fr: 'Fiche technique FR',
    it: 'Scheda tecnica IT',
  },
  download_pdf_zuesaetzliche_dokumente: {
    de: 'Zusätzliche Dokumente',
    en: 'Additional documents',
    fr: 'Documents supplémentaires',
    it: 'Documenti aggiuntivi',
  },
  download_all_pdf: {
    de: 'Alle PDF herunterladen',
    en: 'Download all PDF',
    fr: 'Télécharger tous les PDF',
    it: 'Scarica tutto il PDF',
  },
  download_all_images: {
    de: 'Alle Bilder / Videos herunterladen',
    en: 'Download all images / videos',
    fr: 'Téléchargez toutes les photos / vidéos',
    it: 'Scarica tutte le foto / video',
  },
  preparing_title: {
    de: 'Download wird vorbereitet',
    en: 'Preparing Download',
    fr: 'Préparation du téléchargement',
    it: 'Preparazione al download',
  },
  preparing_desc: {
    de: 'Es kann einige Minuten dauern, bis der Download startet.',
    en: 'It might take some minutes before your download will start.',
    fr: 'Le téléchargement peut prendre quelques minutes.',
    it: "Potrebbero essere necessari alcuni minuti per l'avvio del download.",
  },
  operation_manual_file: {
    de: 'Bedienungsanleitung.pdf',
    en: 'Operation Manual.pdf',
    fr: "Mode d'emploi.pdf",
    it: 'Manuale operativo.pdf',
  },
  post_card_file: {
    de: 'POS KARTE.pdf',
    en: 'POS CARD.pdf',
    fr: 'CARTE POSTALE.pdf',
    it: 'SCHEDA POS.pdf',
  },
  zuesaetzliche_dokumente_file: {
    de: 'Zusätzliche Dokumente.pdf',
    en: 'Additional documents.pdf',
    fr: 'Documents supplémentaires.pdf',
    it: 'Documenti aggiuntivi.pdf',
  },
  search_placeholder: {
    de: 'Geben Sie den Produktnamen ein',
    en: 'Type Product Name',
    fr: 'Type Nom du Produit',
    it: 'Digitare il nome del Prodotto',
  },

  download_test_report: {
    de: 'Testberichte',
    en: 'Test Reports',
    fr: "Rapports d'essai",
    it: 'Rapporti di prova',
  },
  download_award_design: {
    de: 'Design Awards',
    en: 'Design Award',
    fr: 'Prix de design',
    it: 'Premi di design',
  },
  logos_award_design: {
    de: 'Logos Design Awards',
    en: 'Logos Design Awards',
    fr: 'Logos Prix de design',
    it: 'Logos Premi di design',
  },
  logos_testberichte: {
    de: 'Logos Testberichte',
    en: 'Logos Testberichte',
    fr: "Logos Rapports d'essai",
    it: 'Logos rapporti di prova',
  },
  copy_text: {
    de: 'Kopieren',
    en: 'Copy',
    fr: 'Copie',
    it: 'copia',
  },
  copied_text: {
    de: 'Kopiert',
    en: 'Copied',
    fr: 'Copié',
    it: 'Copiato',
  },
  or_text: {
    de: 'Oder',
    en: 'Or',
    fr: 'Ou',
    it: 'O',
  },
  or_text: {
    de: 'Oder',
    en: 'Or',
    fr: 'Ou',
    it: 'O',
  },
};
