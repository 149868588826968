/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Validator from 'validator';
import {Modal, Form, Button} from 'react-bootstrap';
import AlertDismissible from '../Elements/AlertDismissible';

class LoginForm extends Component {
  state = {
    data: {
      email: '',
      password: '',
    },
    errors: {},
    loading: false,
    validated: false,
    showAlert: false,
  };

  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  submitForm = () => {
    const {data} = this.state;
    this.setState({loading: true});
    const errors = this.validateData(data);

    if (Object.entries(errors).length === 0) {
      var bodyFormData = new FormData();
      bodyFormData.append('email', data.email);
      bodyFormData.append('password', data.password);

      this.props
        .login(bodyFormData)
        .then(res => {
          if (res.type === 'success') {
            this.setState({loading: false});
          } else if (res.type === 'error') {
            // console.log('Type', res.type);
            // console.log('Message', res.message);
            this.setState({
              errors: res,
              showAlert: true,
              loading: false,
            });
          }
        })
        .catch(err => {
          console.log(err);
          // this.setState({
          //     errors: res.message,
          //     showAlert: true,
          //     loading: false,
          // });
          // this.setState({
          //     errors: err.response.data.errors,
          //     showAlert: true,
          //     loading: false,
          // });
        });
    } else {
      this.setState({errors, validated: true, loading: false});
    }
  };

  validateData = data => {
    const errors = {};

    if (!data.email) errors.email = 'Please enter Email.';
    else if (!Validator.isEmail(data.email))
      errors.email = 'Please enter valid Email.';

    if (!data.password) errors.password = 'Please Enter Password.';

    return errors;
  };

  closeAlert = () => {
    this.setState({showAlert: false});
  };

  render() {
    const {forgotPasswordForm, errors, validated, loading, data, showAlert} =
      this.state;
    return (
      <Modal
        className="small"
        show={this.props.showModal}
        onHide={() => this.props.toggleModal('loginForm', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors && (
            <AlertDismissible
              show={showAlert}
              variant="danger"
              heading="Something Went Wrong!"
              message={errors.message}
              closeAlert={this.closeAlert}
            />
          )}
          <Form validated={validated} onSubmit={this.submitForm}>
            <Form.Group className="with-icon">
              <i className="far fa-envelope" />
              <Form.Control
                type="email"
                name="email"
                value={data.email}
                required
                placeholder="Your Email"
                onChange={e => this.onChange(e)}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="with-icon">
              <i className="fas fa-lock" />
              <Form.Control
                type="password"
                name="password"
                required
                value={data.password}
                placeholder="Your Password"
                onChange={e => this.onChange(e)}
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-medium solid"
            onClick={() => this.submitForm()}>
            <span>Login</span>
          </Button>
          {loading && <i className="fas fa-spinner fa-pulse loadingIcon" />}
          <div className="guide-links ml-auto">
            <ul>
              <li>
                <Link to="/">Visit Site</Link>
              </li>
              {/* <li>
                                <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        this.props.toggleModal(
                                            "forgotPasswordForm",
                                            true
                                        );
                                        this.props.toggleModal(
                                            "loginForm",
                                            false
                                        );
                                    }}
                                >
                                    Forgot Password
                                </a>
                            </li> */}
            </ul>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

LoginForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

export default LoginForm;
