import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { logout } from "../../../Actions/auth";

class Header extends Component {
    state = {
        data: {},
        user: {
            image: "",
            initials: "",
        },
        validated: false,
        errors: {},
    };
    UNSAFE_componentWillMount = () => {
        const { user } = this.props;
        this.setState({
            user,
        });
    };
    handleOnChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value,
            },
        });
    };
    logout = () => {
        this.props.logout();
    };

    render() {
        const { user, extentSearch, validated, data, errors } = this.state;

        return (
            <div className="header-top-menu">
                <Row>
                    <Col>&nbsp;</Col>
                    <Col>
                        {/* <div className="right-menu-dots">
              <span />
              <span />
              <span />
            </div> */}
                        <div className="user-info">
                            <div className="user-img">{user.initials}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateProp = (state) => ({
    isAuthenticated: !!state.user.access_token,
    user: state.user,
    initials: state.user.initials,
});

Header.propTypes = {
    logout: PropTypes.func.isRequired,
};

export default connect(mapStateProp, { logout })(Header);
