/* eslint-disable no-case-declarations */
import {
  GET_USERS,
  ADD_USER,
  UPDATE_USER,
  REMOVE_USER,
  ADMIN_SAVE_SETTINGS,
  ADMIN_SAVE_MAILS,
} from '../utils/types';

export const adminUserList = (state = {}, action = {}) => {
  let updatedUsers;
  switch (action.type) {
    case GET_USERS:
      return action.users;
    case ADD_USER:
      const oldUsers = state;
      oldUsers.unshift(action.user);
      return oldUsers;
    case UPDATE_USER:
      updatedUsers = state.map(user => {
        if (user.id === action.user.id) return {...user, ...action.user};
        return user;
      });
      return updatedUsers;
    case REMOVE_USER:
      updatedUsers = state.filter(user => {
        return user.id !== action.user.id;
      });
      return updatedUsers;

    default:
      return state;
  }
};

// Admin Settings

export const Settings = (state = {}, action = {}) => {
  switch (action.type) {
    case ADMIN_SAVE_SETTINGS:
      return action.settings;
    default:
      return state;
  }
};

export const Mails = (state = {}, action = {}) => {
  switch (action.type) {
    case ADMIN_SAVE_MAILS:
      return action.mails;
    default:
      return state;
  }
};
