import {
  SAVE_EXPORT_COLUMNS,
  GET_EXPORT_LIST,
  ADD_NEW_LIST,
  UPDATE_NEW_LIST,
  DELETE_NEW_LIST,
} from '../utils/types';

export const singleExportList = (state = {}, action = {}) => {
  switch (action.type) {
    case SAVE_EXPORT_COLUMNS:
      return action.list;
    default:
      return state;
  }
};

export const exportLists = (state = {}, action = {}) => {
  let updatedLists;
  switch (action.type) {
    case GET_EXPORT_LIST:
      return action.lists;
    case ADD_NEW_LIST:
      const oldUsers = state;
      oldUsers.unshift(action.list);
      return oldUsers;
    case UPDATE_NEW_LIST:
      updatedLists = state.map(list => {
        if (list.id === action.list.id) return {...list, ...action.list};
        return list;
      });
      return updatedLists;
    case DELETE_NEW_LIST:
      updatedLists = state.filter(list => {
        return list.id !== action.list.id;
      });
      return updatedLists;
    default:
      return state;
  }
};
