import jwtDecode from "jwt-decode";
import store from "../store";

import queryString from "query-string";

import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import { userLoggedIn } from "../Actions/auth";
import { setHomeLanguage } from "../Actions/HomePageActions";
import { AvailableLang } from "./env";

const accessToken = localStorage.getItem("pim_access_token");

if (accessToken) {
    const decoded = jwtDecode(accessToken);
    const user = {
        uid: decoded.user_id,
        email: decoded.email,
        user_type: decoded.user_type,
        initials: decoded.initials,
        access_token: accessToken,
    };
    store.dispatch(userLoggedIn(user));
    setAuthorizationHeader(accessToken);
}

const queryParams = queryString.parse(window.location.search);
let language_pref = localStorage.getItem("pim_language_pref");
const urlLang = queryParams.lang ? queryParams.lang : "";

if (urlLang !== "" && AvailableLang.includes(urlLang)) {
    language_pref = queryParams.lang;
} else if (!language_pref) {
    language_pref = "en";
}

if (language_pref) {
    store.dispatch(setHomeLanguage(language_pref));
}
