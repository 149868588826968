import React, { Component } from "react";
import { Route } from "react-router-dom";

import GuestRoute from "./GuestRoute";
import AuthRoute from "./AuthRoute";
import AdminRoutes from "./AdminRoutes";

import HomePage from "../Components/Pages/HomePage";
import FaqPage from "../Components/Pages/FaqPage";
import Dashboard from "../Components/Dashboard/Dashboard";
import Login from "../Components/Pages/LoginPage";

import { home_lable } from "../utils/language_untils";
import { connect } from "react-redux";

class Routes extends Component {
    state = {
        lang: "",
    };
    UNSAFE_componentWillMount = () => {
        const { lang } = this.props;
        this.setState({
            lang,
        });
    };
    UNSAFE_componentWillReceiveProps = (nextprops) => {
        const { lang } = nextprops;
        // console.log("nextprops", nextprops);
        this.setState({
            lang,
        });
    };
    render() {
        const { lang } = this.state;
        window.document.title = home_lable.window_title[lang];
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", home_lable.meta_content[lang]);

        return (
            <div className="app-container">
                <AdminRoutes
                    location={this.props.location}
                    path="/admin/"
                    component={Dashboard}
                />
                <GuestRoute
                    location={this.props.location}
                    exact
                    path="/login"
                    component={Login}
                />
                <Route
                    location={this.props.location}
                    path="/faq"
                    exact
                    component={FaqPage}
                />
                <Route
                    location={this.props.location}
                    path="/"
                    exact
                    component={HomePage}
                />
            </div>
        );
    }
}

const mapStateProps = (state) => {
    return {
        lang: state.languagePref,
    };
};

export default connect(mapStateProps, {})(Routes);
