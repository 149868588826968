import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import Validator from 'validator';
import AlertDismissible from '../Elements/AlertDismissible';

import LoginModal from '../Modals/LoginForm';
// import ForgotPasswordForm from "../Modals/ForgotPasswordForm";

import {login, signup} from '../../Actions/auth';

class LoginPage extends Component {
  state = {
    data: {
      email: '',
      password: '',
    },
    errors: {},
    loading: false,
    validated: false,
    showAlert: false,

    forgotPasswordForm: false,
  };

  toggleModal = (modal, value) => {
    switch (modal) {
      case 'loginForm':
        this.setState({loginForm: value});
        break;
      case 'signUpForm':
        this.setState({signUpForm: value});
        break;

      case 'forgotPasswordForm':
        this.setState({forgotPasswordForm: value});
        break;

      default:
        break;
    }
  };

  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  submitForm = () => {
    const {data, loading} = this.state;
    const errors = this.validateData(data);

    if (Object.entries(errors).length === 0 && !loading) {
      this.setState({loading: true});

      var bodyFormData = new FormData();
      bodyFormData.append('email', data.email);
      bodyFormData.append('password', data.password);

      this.props
        .login(bodyFormData)
        .then(res => {
          if (res.type === 'success') {
            let redirect;
            this.props.currentuser.user_type === 'Administrator'
              ? (redirect = '/admin/')
              : (redirect = '/');
            this.toggleModal('loginForm', false);
            this.props.history.push(redirect);

            this.setState({loading: false});
          } else if (res.type === 'error') {
            this.setState({
              errors: res,
              showAlert: true,
              loading: false,
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({errors, validated: true, loading: false});
    }
  };

  validateData = data => {
    const errors = {};

    if (!data.email) errors.email = 'Please enter Email.';
    else if (!Validator.isEmail(data.email))
      errors.email = 'Please enter valid Email.';

    if (!data.password) errors.password = 'Please Enter Password.';

    return errors;
  };

  closeAlert = () => {
    this.setState({showAlert: false});
  };

  render() {
    const {floatingAlert, loginForm, forgotPasswordForm, currnetTab} =
      this.state;
    const {errors, validated, loading, data, showAlert} = this.state;
    return (
      <React.Fragment>
        {!this.props.isAuthenticated && (
          <React.Fragment>
            <LoginModal
              login={this.login}
              location={this.props.location}
              showModal={loginForm}
              toggleModal={this.toggleModal}
            />

            {/* <ForgotPasswordForm
                            resetRequest={this.resetRequest}
                            resetPasword={this.resetPasword}
                            location={this.props.location}
                            showModal={forgotPasswordForm}
                            toggleModal={this.toggleModal}
                        /> */}
          </React.Fragment>
        )}

        <div className="login-form-wrapper">
          <h2>Login</h2>
          {errors && (
            <AlertDismissible
              show={showAlert}
              variant="danger"
              heading=""
              message={errors.message}
              closeAlert={this.closeAlert}
            />
          )}
          <Form validated={validated} onSubmit={() => this.submitForm()}>
            <Form.Group className="with-icon">
              <i className="far fa-envelope" />
              <Form.Control
                type="email"
                name="email"
                value={data.email}
                required
                placeholder="Your Email"
                onChange={e => this.onChange(e)}
              />
              {errors.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="with-icon">
              <i className="fas fa-lock" />
              <Form.Control
                type="password"
                name="password"
                required
                value={data.password}
                placeholder="Your Password"
                onChange={e => this.onChange(e)}
              />
              {errors.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div class="button-wrapper">
              <Button
                className="btn btn-medium solid"
                onClick={() => this.submitForm()}>
                <span>Login</span>
              </Button>
              {loading && <i className="fas fa-spinner fa-pulse loadingIcon" />}
              <div className="guide-links ml-auto">
                <ul>
                  <li>
                    <Link to="/">Visit Site</Link>
                  </li>
                  {/* <li>
                                        <a
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                this.props.toggleModal(
                                                    "forgotPasswordForm",
                                                    true
                                                );                                                
                                            }}
                                        >
                                            Forgot Password
                                        </a>
                                    </li> */}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateProp = state => {
  return {
    isAuthenticated: !!state.user.accessToken,
    recentProjects: state.recentProjects,
    currentuser: state.user,
  };
};

export default connect(mapStateProp, {login})(LoginPage);
