import { USER_LOGGED_IN, USER_SIGN_UP, USER_LOGOUT } from "../utils/types";

export default (state = {}, action = {}) => {
    switch (action.type) {
        case USER_LOGGED_IN:
            return action.user;
        case USER_SIGN_UP:
            return action.user;
        case USER_LOGOUT:
            return {};

        default:
            return state;
    }
};
