import axios from 'axios';

import {serverUrl} from './env';

const postData = (url, data) =>
  axios({
    method: 'post',
    url,
    data,
    config: {headers: {'Content-Type': 'multipart/form-data'}},
  }).then(res => res.data);

const getData = url => axios.get(url).then(res => res.data);

export default {
  user: {
    login: data => postData(`${serverUrl}/auth/login`, data),
    signup: user => postData(`${serverUrl}/signup`, {user}),
    resetRequest: email =>
      axios
        .post(`${serverUrl}/auth/reset-request`, {email})
        .then(res => res.data.success),
    verifyResetToken: token =>
      axios
        .post(`${serverUrl}/auth/verify-token`, {token})
        .then(res => res.data.success),
    updatePassword: data =>
      axios.post(`${serverUrl}/update-password`, {data}).then(res => res.data),
    confirmMyAccount: token =>
      axios
        .post(`${serverUrl}/confirmation`, {token})
        .then(res => res.data.success),
  },
  admin: {
    getusers: page => getData(`${serverUrl}/admin/users/page/${page}`),
    adminAddUser: data => postData(`${serverUrl}/auth/add-user`, data),
    adminEditUser: data => postData(`${serverUrl}/auth/edit-user`, data),
    adminRemoveUser: id => getData(`${serverUrl}/auth/remove-user/${id}`),

    getProducts: (page, search) => {
      const postUrl = search
        ? `${serverUrl}/admin/products/page/${page}?search=${search}`
        : `${serverUrl}/admin/products/page/${page}`;

      return getData(postUrl);
    },
    adminEditProduct: data => postData(`${serverUrl}/admin/product/edit`, data),
    adminRemoveProduct: id => getData(`${serverUrl}/admin/product/${id}`),
    getCategories: page =>
      getData(`${serverUrl}/admin/product-category/page/${page}`),
    editCategory: data =>
      postData(`${serverUrl}/admin/product-category/edit`, data),

    getFamilies: page =>
      getData(`${serverUrl}/admin/product-families/page/${page}`),
    adminEditFamilie: data =>
      postData(`${serverUrl}/admin/product-families/edit`, data),
    adminRemoveFamilie: familie_id =>
      getData(`${serverUrl}/admin/product-families/remove/${familie_id}`),
    saveSettings: data => postData(`${serverUrl}/admin/save-settings/`, data),
    getSettings: () => getData(`${serverUrl}/admin/get-settings/`),

    importNow: () =>
      postData(`${serverUrl}/admin/products/import?web_import=true`),
    updateImageStatus: data =>
      postData(`${serverUrl}/admin/update-product-image-status`, data),
    getImageStatus: product_id =>
      getData(`${serverUrl}/admin/get-product-image-status/${product_id}`),

    saveExportColumns: data =>
      postData(`${serverUrl}/admin/save-export-columns`, data),
    getExportColumns: data =>
      postData(`${serverUrl}/admin/get-export-columns`, data),
    getExportLists: (page, search) =>
      getData(
        `${serverUrl}/admin/get-export-list/page/${page}?search=${search}`,
      ),
    addNewExportList: data =>
      postData(`${serverUrl}/admin/add-new-export-list`, data),
    updateExportList: data =>
      postData(`${serverUrl}/admin/update-new-export-list`, data),
    deleteExportList: id =>
      postData(`${serverUrl}/admin/remove-new-export-list`, id),
    getPreviewProducts: id =>
      postData(`${serverUrl}/admin/get-export-preview-products`, id),
    exportAllCsv: () => getData(`${serverUrl}/admin/export-csv?web_run=true`),
    exportList: listId =>
      getData(`${serverUrl}/admin/export-csv/${listId}/?web_run=true`),
  },
  home: {
    getFilterOptions: () => getData(`${serverUrl}/home/get-filters/`),
    get_famillies: data => postData(`${serverUrl}/home/get-famillies/`, data),
    getHomeProducts: data => postData(`${serverUrl}/home/get-products/`, data),
    getSearchProducts: data => postData(`${serverUrl}/home/get-search/`, data),
    downloadPdf: id => getData(`${serverUrl}/products/generate-pdf/${id}`),
    getImageStatus: product_id =>
      getData(`${serverUrl}/get-product-image-status/${product_id}`),
    getProductWithId: product_id =>
      getData(`${serverUrl}/get-product-with-id/${product_id}`),
  },
};
