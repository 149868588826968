/* eslint-disable no-case-declarations */
import {
    ADMIN_GET_FAMILIES,
    ADMIN_UPDATE_FAMILIE,
    ADMIN_REMOVE_FAMILIE,
} from "../utils/types";

export default (state = {}, action = {}) => {
    let updatedFamilie;
    switch (action.type) {
        // Projects API
        case ADMIN_GET_FAMILIES:
            return action.families;
        case ADMIN_UPDATE_FAMILIE:
            const updatedFamilies = state.map((familie) => {
                if (familie.id === action.familie.id)
                    return { ...familie, ...action.familie };
                return familie;
            });
            return updatedFamilies;
        case ADMIN_REMOVE_FAMILIE:
            updatedFamilie = state.filter((familie) => {
                return familie.id !== action.familie.id;
            });
            return updatedFamilie;
        default:
            return state;
    }
};
