import { combineReducers } from "redux";
import user from "./UserReducer";
// import projects from "./ProjectReducer";
// import { myProjects, recentProjects, Orders } from "./myProjects";
import { adminUserList, Settings, Mails } from "./AdminUserReducer";
import adminProductList from "./AdminProductReducer";
import adminCategoryList from "./AdminProductCategoryReducer";
import adminFamiliesList from "./AdminProductFamiliesReducer";

import { productsList, filtersList, languagePref, filterFamillies } from "./HomeReducers";
import { singleExportList, exportLists } from "./ExportListReducers";

export default combineReducers({
    user,
    adminUserList,
    adminProductList,
    adminCategoryList,
    adminFamiliesList,
    filtersList,
    filterFamillies,
    productsList,
    singleExportList,
    exportLists,
    languagePref,
    Settings,
});
