import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

import "./Assets/css/style.css";
// import "./Assets/sass/style.scss";
import "./Assets/css/animate.css";

import "./Assets/lib/slick/slick.min.css";
import "./Assets/lib/slick/slick-theme.min.css";

import "./utils/userLoginCheck";

import store from "./store";
import { Provider } from "react-redux";

import App from "./App";
import * as serviceWorker from "./utils/serviceWorker";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Route component={App} />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
