/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import { Modal } from "react-bootstrap";

import { home_lable } from "../../utils/language_untils";

export const PreparingDownload = (props) => {
    return (
        <Modal className="small" show={props.show}>
            <Modal.Header>
                <Modal.Title>{home_lable.preparing_title[props.currentLang]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{home_lable.preparing_desc[props.currentLang]}</p>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    );
};
