import React, {Component} from 'react';

import {connect} from 'react-redux';
import {Card, Image, Form, Button} from 'react-bootstrap';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import AlertDismissible from '../../../Elements/AlertDismissible';
import {
  adminGetProducts,
  adminEditProduct,
} from '../../../../Actions/AdminActions';
import ProductModal from '../Modals/ProductModal';
import UpdateProductModal from '../Modals/UpdateProductModal';

import defaultImg from '../../../../Assets/images/default-img.jpg';

class Products extends Component {
  state = {
    data: {
      search: '',
      searchLoading: false,
    },
    products: [],
    showProductModal: false,
    updateProductModal: false,
    modalProduct: {},
    errors: [],
    validated: false,
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
    pagginInfo: [],
    currentIndex: '',
    extentSearch: '',
  };

  UNSAFE_componentWillMount = () => {
    const url = window.location.href;
    const pageParams = url.split('/page/');
    const page = pageParams[1] ? pageParams[1] : '1';

    this.get_products(page);
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {products} = nextProps;

    if (Object.entries(products).length !== 0) {
      products.map((product, index) => {
        if (typeof product.status !== 'boolean') {
          products[index]['status'] = product.status === '1' ? true : false;
        }
      });
    }

    this.setState({
      products,
    });
  };

  get_products = (page = 1, search = '') => {
    this.props.adminGetProducts(page, search).then(res => {
      if (res.type === 'success') {
        this.setState({
          pagginInfo: res.pagging_info,
          currentIndex: res.pagging_info.indexStart,
          data: {
            ...this.state.data,
            searchLoading: false,
          },
        });
      } else if (res.type === 'error') {
      }
    });
    return false;
  };

  hadleSearch = e => {
    const {search} = this.state.data;

    const url = window.location.href;
    const pageParams = url.split('/page/');
    const page = pageParams[1] ? pageParams[1] : '1';

    this.setState({
      data: {
        ...this.state.data,
        searchLoading: true,
      },
    });

    this.get_products(page, search);

    return false;
  };

  handleOnChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

    // this.hadleSearch();
    return false;
  };

  toggleModal = (modal, value, product) => {
    switch (modal) {
      case 'showProductModal':
        this.setState({
          showtProductModal: value,
          modalProduct: product,
        });
        break;
      case 'updateProductModal':
        this.setState({
          updateProductModal: value,
          modalProduct: product,
        });
        break;
      default:
        break;
    }
    return '';
  };

  renderProductItem = (product, index, startIndex) => {
    startIndex = startIndex + index;

    // console.log("Product:", product);
    const Img = product.product_image[0]
      ? product.product_image[0]
      : defaultImg;

    return (
      <tr key={`product_${index}`}>
        <td>{`${startIndex}`}</td>
        <td>
          <LazyLoadImage alt={''} effect="blur" src={Img} />
          {/* <Image src={Img} alt="" className="product-img" /> */}
        </td>
        <td>{product.product_name}</td>
        <td>{product.familie_id}</td>
        <td>{product.category_id}</td>
        <td>{product.artikelnummer}</td>
        <td>{product.ean_nummer}</td>
        <td className="text-center">
          <Form.Check
            type="switch"
            id={`product_${product.id}`}
            checked={product.status ? 'checked' : ''}
            onChange={e => {
              this.updateProductStatus(index, product.id, e);
            }}
            label=""
          />
        </td>
        <td className="text-center">
          <Button
            onClick={() => {
              this.toggleModal('showProductModal', true, product);
            }}
            className="btn solid mr-2 btn-rounded">
            <i className="fas fa-eye" />
          </Button>
          <Button
            onClick={() => {
              this.toggleModal('updateProductModal', true, product);
            }}
            className="btn solid btn-danger btn-rounded">
            <i className="fas fa-edit" />
          </Button>
        </td>
      </tr>
    );
  };

  updateProductStatus = (index, product_id, e) => {
    const {products} = this.state;

    // console.log("why this??");

    products[index]['status'] = e.target.checked;

    this.setState(
      {
        products,
      },
      () => {
        const {products} = this.state;
        const status = products[index].status ? 1 : 0;
        const prodductData = new FormData();
        prodductData.append('product_id', product_id);
        prodductData.append('status', status);

        this.props.adminEditProduct(prodductData).then(res => {
          if (res.type === 'success') {
            this.setState({
              alertData: {
                heading: '',
                message: res.message,
                show: true,
                variant: 'success',
              },
            });
          } else {
            this.setState({
              alertData: {
                heading: '',
                message: res.message,
                show: true,
                variant: 'danger',
              },
            });
          }
        });
      },
    );
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        heading: '',
        message: '',
        show: false,
        variant: '',
      },
    });
  };

  toggleSerachBox = status => {
    this.setState({
      extentSearch: status,
    });
  };

  _render_pagging = () => {
    const {pagginInfo, data} = this.state;

    const nextClass = pagginInfo.next === 'disable' ? 'disabled' : '';
    const prvClass = pagginInfo.previous === 'disable' ? 'disabled' : '';

    return (
      <div className="pagging-info">
        {pagginInfo.total_item} items
        <span
          className={`nav-btn prv ${prvClass}`}
          onClick={() => {
            if (pagginInfo.previous !== 'disable')
              this.get_products(pagginInfo.previous, data.search);
          }}>
          ‹
        </span>
        <span className="page-text">
          Current Page {pagginInfo.current} of {pagginInfo.total_pages}
        </span>
        <span
          className={`nav-btn next ${nextClass}`}
          onClick={() => {
            if (pagginInfo.next !== 'disable')
              this.get_products(pagginInfo.next, data.search);
          }}>
          ›
        </span>
      </div>
    );
  };

  render() {
    const {
      data,
      alertData,
      products,
      modalProduct,
      pagginInfo,
      showtProductModal,
      updateProductModal,
      extentSearch,
      errors,
      validated,
    } = this.state;

    return (
      <React.Fragment>
        <div className="pageHeading d-flex">
          <h2>Products</h2>
          <div className="extras align-self-center ml-auto"></div>

          <div className={`search-area ${extentSearch}`}>
            {data.searchLoading && (
              <span>
                <i className="fas fa-spinner fa-pulse loadingIcon" />
              </span>
            )}
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.hadleSearch();
              }}>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="search"
                  value={data.search}
                  required
                  placeholder="Search..."
                  onFocus={() => this.toggleSerachBox('open')}
                  onBlur={() => this.toggleSerachBox('')}
                  onChange={e => {
                    this.handleOnChange(e);
                  }}
                />
                {errors.search && (
                  <Form.Control.Feedback type="invalid">
                    {errors.search}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <button
                type="button"
                className="search-btn"
                onClick={() => {
                  this.hadleSearch();
                }}>
                <i className="fas fa-search" />
              </button>
            </Form>
          </div>
        </div>
        {alertData.show && (
          <AlertDismissible
            show={alertData.show}
            variant={alertData.variant}
            heading={alertData.heading}
            message={alertData.message}
            closeAlert={this.closeAlert}
          />
        )}
        <Card>
          <Card.Body>
            {this._render_pagging()}

            <div className="data-listing">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th width="1%" />
                    <th width="2%">Product Image</th>
                    <th width="10%">Product Name</th>
                    <th width="4%" className="">
                      Familie
                    </th>
                    <th width="4%" className="">
                      Category
                    </th>
                    <th width="4%" className="">
                      Artikelnummer
                    </th>
                    <th width="4%" className="">
                      EAN Nummer
                    </th>
                    <th width="2%" className="text-center">
                      Status
                    </th>
                    <th width="2%" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(products).length !== 0 &&
                    products.map((product, index) =>
                      this.renderProductItem(
                        product,
                        index,
                        pagginInfo.indexStart,
                      ),
                    )}
                  {Object.entries(products).length === 0 && (
                    <tr>
                      <td colSpan="9">
                        <p className="text-center">No Products Available.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {this._render_pagging()}
          </Card.Body>
        </Card>

        <ProductModal
          product={modalProduct}
          showModal={showtProductModal}
          toggleModal={(modal, value, product) =>
            this.toggleModal(modal, value, product)
          }
        />

        {updateProductModal && (
          <UpdateProductModal
            product={modalProduct}
            showModal={updateProductModal}
            toggleModal={(modal, value, product) =>
              this.toggleModal(modal, value, product)
            }
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  products: state.adminProductList,
});

export default connect(mapStateProps, {adminGetProducts, adminEditProduct})(
  Products,
);
