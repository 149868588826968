import React, {Component} from 'react';
import {Modal, Form, Button, Image} from 'react-bootstrap';
import {connect} from 'react-redux';

import AlertDismissible from '../../../Elements/AlertDismissible';

import {
  getImageStatus,
  updateImageStatus,
} from '../../../../Actions/AdminActions';

class updateProductModal extends Component {
  state = {
    product: {},
    product_images: [
      {key: 'iso_1', value: '', status: false},
      {key: 'iso_2', value: '', status: false},
      {key: 'iso_3', value: '', status: false},
      {key: 'iso_4', value: '', status: false},
      {key: 'front', value: '', status: false},
      {key: 'side', value: '', status: false},
      {key: 'top', value: '', status: false},
      {key: 'detail_1', value: '', status: false},
      {key: 'detail_2', value: '', status: false},
      {key: 'detail_3', value: '', status: false},
      {key: 'detail_4', value: '', status: false},
      {key: 'detail_5', value: '', status: false},
      {key: 'detail_6', value: '', status: false},
      {key: 'detail_7', value: '', status: false},
      {key: 'detail_8', value: '', status: false},
      {key: 'detail_9', value: '', status: false},
      {key: 'lifestyle_1', value: '', status: false},
      {key: 'lifestyle_2', value: '', status: false},
      {key: 'lifestyle_3', value: '', status: false},
      {key: 'lifestyle_4', value: '', status: false},
      {key: 'lifestyle_5', value: '', status: false},
      {key: 'lifestyle_6', value: '', status: false},
      {key: 'lifestyle_7', value: '', status: false},
      {key: 'lifestyle_8', value: '', status: false},
      {key: 'lifestyle_9', value: '', status: false},
      {key: 'lifestyle_10', value: '', status: false},
      {key: 'lifestyle_11', value: '', status: false},
      {key: 'lifestyle_12', value: '', status: false},
      {key: 'lifestyle_13', value: '', status: false},
      {key: 'lifestyle_14', value: '', status: false},
      {key: 'lifestyle_15', value: '', status: false},
      {key: 'lifestyle_16', value: '', status: false},
      {key: 'lifestyle_17', value: '', status: false},
      {key: 'lifestyle_18', value: '', status: false},
      {key: 'lifestyle_19', value: '', status: false},
      {key: 'lifestyle_20', value: '', status: false},
      {key: 'lifestyle_21', value: '', status: false},
      {key: 'lifestyle_22', value: '', status: false},
      {key: 'lifestyle_23', value: '', status: false},
      {key: 'lifestyle_24', value: '', status: false},
      {key: 'lifestyle_26', value: '', status: false},
      {key: 'lifestyle_27', value: '', status: false},
      {key: 'lifestyle_28', value: '', status: false},
      {key: 'lifestyle_29', value: '', status: false},
      {key: 'lifestyle_30', value: '', status: false},
      {key: 'lifestyle_31', value: '', status: false},
      {key: 'lifestyle_32', value: '', status: false},
      {key: 'lifestyle_33', value: '', status: false},
      {key: 'lifestyle_34', value: '', status: false},
      {key: 'lifestyle_35', value: '', status: false},
      {key: 'lifestyle_36', value: '', status: false},
      {key: 'lifestyle_37', value: '', status: false},
      {key: 'lifestyle_38', value: '', status: false},
      {key: 'lifestyle_39', value: '', status: false},
      {key: 'lifestyle_40', value: '', status: false},
      {key: 'bild_feature_1_de', value: '', status: false},
      {key: 'bild_feature_1_en', value: '', status: false},
      {key: 'bild_feature_1_fr', value: '', status: false},
      {key: 'bild_feature_1_it', value: '', status: false},
      {key: 'bild_feature_2_de', value: '', status: false},
      {key: 'bild_feature_2_en', value: '', status: false},
      {key: 'bild_feature_2_fr', value: '', status: false},
      {key: 'bild_feature_2_it', value: '', status: false},
      {key: 'bild_feature_3_de', value: '', status: false},
      {key: 'bild_feature_3_en', value: '', status: false},
      {key: 'bild_feature_3_fr', value: '', status: false},
      {key: 'bild_feature_3_it', value: '', status: false},
      {key: 'bild_feature_4_de', value: '', status: false},
      {key: 'bild_feature_4_en', value: '', status: false},
      {key: 'bild_feature_4_fr', value: '', status: false},
      {key: 'bild_feature_4_it', value: '', status: false},
    ],
    alertData: {
      heading: '',
      message: '',
      show: false,
      variant: '',
    },
  };

  componentWillMount = () => {
    const {product} = this.props;
    const {product_images} = this.state;
    this.props.getImageStatus(product.id).then(res => {
      if (res.type === 'success') {
        const imageStatus = res.image_status;

        product_images.map((image, index) => {
          for (const [key, value] of Object.entries(imageStatus)) {
            if (image.key === key) {
              const stat = value === 'Active' ? true : false;
              product_images[index]['status'] = stat;
            }
          }
        });

        this.setState({
          product_images,
        });
      }

      this.updateImgUrl();
    });
  };

  closeAlert = () => {
    this.setState({
      alertData: {
        heading: '',
        message: '',
        show: false,
        variant: '',
      },
    });
  };

  updateImgUrl = () => {
    const {product} = this.props;
    const {product_images} = this.state;
    const products_fields = product.product_fields;

    //console.log("Image URL:", products_fields);

    if (products_fields) {
      product_images.map((image, index) => {
        // console.log(image.key);
        const imgUrl = products_fields[image.key]
          ? products_fields[image.key][0]
          : '';
        // console.log("Image URL:", imgUrl);
        product_images[index]['value'] = imgUrl;
      });

      this.setState({
        product,
        product_images,
      });
    }
  };

  updateImageStatus = (index, product_id, e) => {
    const {product_images} = this.state;
    const checkStatus = e.target.checked ? 'Active' : 'Inactive';

    product_images[index]['status'] = e.target.checked;

    this.setState(
      {
        product_images,
      },
      () => {
        const data = new FormData();
        data.append('product_id', product_id);
        data.append('key', product_images[index]['key']);
        data.append('status', checkStatus);

        this.props.updateImageStatus(data).then(res => {
          if (res.type === 'success') {
            this.setState({
              alertData: {
                heading: '',
                message: res.message,
                show: true,
                variant: 'success',
              },
            });
          } else {
            this.setState({
              alertData: {
                heading: '',
                message: res.message,
                show: true,
                variant: 'danger',
              },
            });
          }
        });
      },
    );
  };

  rentderItem = (item, index) => {
    const {product} = this.state;
    return (
      <tr key={index}>
        <td className="text-center">
          {item.value && <Image className="" src={item.value} alt="" />}
        </td>
        <td>{item.key}</td>
        <td className="text-center">
          <Form.Check
            type="switch"
            id={`product_image_${item.key}_${index}`}
            checked={item.status ? 'checked' : ''}
            onChange={e => {
              this.updateImageStatus(index, product.id, e);
            }}
            label=""
          />
        </td>
      </tr>
    );
  };

  render() {
    const {alertData, product_images} = this.state;
    return (
      <React.Fragment>
        <Modal
          className="max"
          show={this.props.showModal}
          onHide={() => {
            this.props.toggleModal('updateProductModal', false, '');
          }}>
          <Modal.Header closeButton>
            <Modal.Title>&nbsp;</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alertData.show && (
              <AlertDismissible
                show={alertData.show}
                variant={alertData.variant}
                heading={alertData.heading}
                message={alertData.message}
                closeAlert={this.closeAlert}
              />
            )}
            <table className="update-image-state">
              <tr>
                <th width={100}>Image</th>
                <th>Label</th>
                <th width={50}>Status</th>
              </tr>
              {product_images &&
                product_images.map((item, index) =>
                  this.rentderItem(item, index),
                )}
            </table>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({});

export default connect(mapStateProps, {getImageStatus, updateImageStatus})(
  updateProductModal,
);
