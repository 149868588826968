/* eslint-disable no-case-declarations */
import {
    HOME_FILTERS,
    HOME_FAMILIES,
    GET_FILTER_PRODUCTS,
    SET_LANGUAGE_PREF,
    SAVE_EXPORT_COLUMNS,
} from "../utils/types";

export const filtersList = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case HOME_FILTERS:
            return action.filters;
        default:
            return state;
    }
};

export const filterFamillies = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case HOME_FAMILIES:
            return action.families;
        default:
            return state;
    }
};

export const productsList = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case GET_FILTER_PRODUCTS:
            return action.products;
        default:
            return state;
    }
};

export const languagePref = (state = {}, action = {}) => {
    switch (action.type) {
        // Projects API
        case SET_LANGUAGE_PREF:
            return action.language;
        default:
            return state;
    }
};

