// User Types
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_SIGN_UP = "USER_SIGN_UP";
export const USER_LOGOUT = "USER_LOGOUT";

// Admin User Types
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";

// Admin Products Types
export const ADMIN_GET_PRODUCTS = "ADMIN_GET_PRODUCTS";
export const ADMIN_ADD_PRODUCT = "ADMIN_ADD_PRODUCT";
export const ADMIN_UPDATE_PRODUCT = "ADMIN_UPDATE_PRODUCT";
export const ADMIN_REMOVE_PRODUCT = "ADMIN_REMOVE_PRODUCT";

// Admin Product Category Types
export const ADMIN_GET_CATEGORIES = "ADMIN_GET_CATEGORIES";
export const ADMIN_UPDATE_CATEGORY = "ADMIN_UPDATE_CATEGORY";
export const ADMIN_REMOVE_CATEGORY = "ADMIN_REMOVE_CATEGORY";
// export const ADMIN_ADD_PRODUCT = "ADMIN_ADD_PRODUCT";

// Admin Product Families Types
export const ADMIN_GET_FAMILIES = "ADMIN_GET_FAMILIES";
export const ADMIN_UPDATE_FAMILIE = "ADMIN_UPDATE_FAMILIE";
export const ADMIN_REMOVE_FAMILIE = "ADMIN_REMOVE_FAMILIE";
// export const ADMIN_ADD_PRODUCT = "ADMIN_ADD_PRODUCT";

// Admin Settings Type
export const ADMIN_SAVE_SETTINGS = "ADMIN_SAVE_SETTINGS";
export const ADMIN_SAVE_MAILS = "ADMIN_SAVE_MAILS";

// HOME Page Filter
export const HOME_FILTERS = "HOME_FILTERS";
export const HOME_FAMILIES = "HOME_FAMILIES";
export const GET_FILTER_PRODUCTS = "GET_FILTER_PRODUCTS";

// Language Prefrence
export const SET_LANGUAGE_PREF = "SET_LANGUAGE_PREF";

// Saving Export Columns
export const EXPORT_PREVIEW_LIST = "EXPORT_PREVIEW_LIST";
export const GET_EXPORT_LIST = "GET_EXPORT_LIST";
export const ADD_NEW_LIST = "ADD_NEW_LIST";
export const UPDATE_NEW_LIST = "UPDATE_NEW_LIST";
export const DELETE_NEW_LIST = "DELETE_NEW_LIST";
export const SAVE_EXPORT_COLUMNS = "SAVE_EXPORT_COLUMNS";
