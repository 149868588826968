import React from "react";
import PropTypes from "prop-types";

const AlertDismissible = props => {
  return (
    <React.Fragment>
      {props.show && (
        <div className={`alert alert-${props.variant}`}>
          {props.closeAlert && (
            <button
              type="button"
              className="close"
              onClick={() => props.closeAlert()}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close alert</span>
            </button>
          )}
          {props.heading && (
            <div className="alert-heading h4">{props.heading}</div>
          )}
          <p>{props.message}</p>
        </div>
      )}
    </React.Fragment>
  );
};

AlertDismissible.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  closeAlert: PropTypes.func.isRequired
};

export default AlertDismissible;
