import React, { Component } from "react";
import { connect } from "react-redux";

import { Accordion, Card, Button } from "react-bootstrap";
import SideBarMenu from "../SideBarMenu";
import { login, signup } from "../../Actions/auth";

class FaQPage extends Component {
    state = {
        faqs: [
            {
                title: {
                    en: "What is Lorem Ipsum? EN",
                    de: "What is Lorem Ipsum? DE",
                    fr: "What is Lorem Ipsum? FR",
                    it: "What is Lorem Ipsum? IT",
                },
                content: {
                    en: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    de: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    fr: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    it: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                },
            },
            {
                title: {
                    en: "Why do we use it?",
                    de: "Why do we use it?",
                    fr: "Why do we use it?",
                    it: "Why do we use it?",
                },
                content: {
                    en: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    de: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    fr: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    it: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                },
            },
            {
                title: {
                    en: "Why do we use it?",
                    de: "Why do we use it?",
                    fr: "Why do we use it?",
                    it: "Why do we use it?",
                },
                content: {
                    en: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    de: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    fr: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                    it: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>`,
                },
            },
        ],
        currentLang: "",
    };

    UNSAFE_componentWillMount = () => {
        const { currentLang } = this.props;
        this.setState({
            currentLang,
        });
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            currentLang: nextProps.currentLang,
        });
    }

    render() {
        const { faqs, currentLang } = this.state;

        return (
            <React.Fragment>
                <SideBarMenu toggleModal={this.toggleModal} />
                <div id="content-wrapper" className="content-wrapper front open">
                    <div className="site-heading">
                        <h1>FAQ</h1>

                        <Accordion>
                            {Object.entries(faqs).length > 0 &&
                                faqs.map((item, index) => (
                                    <Card id={`id-${index}`}>
                                        <Card.Header>
                                            <Accordion.Toggle variant="link" eventKey={`id-${index}`}>
                                                {item.title[currentLang]}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`id-${index}`}>
                                            <Card.Body>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.content[currentLang],
                                                    }}
                                                ></div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                        </Accordion>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateProrp = (state) => {
    return {
        isAuthenticated: !!state.user.accessToken,
        recentProjects: state.recentProjects,
        currentuser: state.user,
        currentLang: state.languagePref,
    };
};

export default connect(mapStateProrp, {})(FaQPage);
