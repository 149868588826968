import {
    GET_USERS,
    ADD_USER,
    UPDATE_USER,
    REMOVE_USER,
    ADMIN_GET_PRODUCTS,
    ADMIN_UPDATE_PRODUCT,
    ADMIN_REMOVE_PRODUCT,
    ADMIN_GET_CATEGORIES,
    ADMIN_UPDATE_CATEGORY,
    ADMIN_GET_FAMILIES,
    ADMIN_UPDATE_FAMILIE,
    ADMIN_REMOVE_FAMILIE,
    ADMIN_SAVE_SETTINGS,
    ADMIN_SAVE_MAILS,
    SAVE_EXPORT_COLUMNS,
} from "../utils/types";
import api from "../utils/api";

// Admin User Actions
export const getUsers = (users) => ({
    type: GET_USERS,
    users,
});

export const addUser = (user) => ({
    type: ADD_USER,
    user,
});

export const editUser = (user) => ({
    type: UPDATE_USER,
    user,
});

export const removeUser = (user) => ({
    type: REMOVE_USER,
    user,
});

export const adminGetUsers = (page) => (dispatch) =>
    api.admin.getusers(page).then((res) => {
        const users = res.users;
        if (users) {
            dispatch(getUsers(users));
        }
    });

export const adminAddUser = (data) => (dispatch) =>
    api.admin.adminAddUser(data).then((res) => {
        const user = res.user;
        if (user) {
            dispatch(addUser(user));
        }
        return res;
    });

export const adminEditUser = (data) => (dispatch) =>
    api.admin.adminEditUser(data).then((res) => {
        const user = res.user;
        if (user) {
            dispatch(editUser(user));
        }
        return res;
    });

export const adminRemoveUser = (data) => (dispatch) =>
    api.admin.adminRemoveUser(data).then((res) => {
        const user = res.user;
        if (user) {
            dispatch(removeUser(user));
        }
        return res;
    });

// Admin Families Actions
export const getFamilies = (families) => ({
    type: ADMIN_GET_FAMILIES,
    families,
});

export const editFamilie = (familie) => ({
    type: ADMIN_UPDATE_FAMILIE,
    familie,
});
export const removeFamilie = (familie) => ({
    type: ADMIN_REMOVE_FAMILIE,
    familie,
});

export const adminGetFamilies = (page) => (dispatch) =>
    api.admin.getFamilies(page).then((res) => {
        const families = res.families;
        if (families) {
            dispatch(getFamilies(families));
        }
        return res;
    });

export const adminEditFamilie = (data) => (dispatch) =>
    api.admin.adminEditFamilie(data).then((res) => {
        const familie = res.familie;
        if (familie) {
            familie.status = familie.status === "1" ? true : false;
            dispatch(editFamilie(familie));
        }
        return res;
    });

export const adminRemoveFamilie = (data) => (dispatch) =>
    api.admin.adminRemoveFamilie(data).then((res) => {
        const familie = res.familie;
        if (familie) {
            dispatch(removeFamilie(familie));
        }
        return res;
    });

// Admin Categories Actions
export const getCategories = (categories) => ({
    type: ADMIN_GET_CATEGORIES,
    categories,
});

export const editCategory = (category) => ({
    type: ADMIN_UPDATE_CATEGORY,
    category,
});

export const adminGetCategories = (page) => (dispatch) =>
    api.admin.getCategories(page).then((res) => {
        const categories = res.categories;
        if (categories) {
            dispatch(getCategories(categories));
        }
        return res;
    });

export const adminEditCategory = (page) => (dispatch) =>
    api.admin.editCategory(page).then((res) => {
        const category = res.category;
        if (category) {
            category.status = category.status === "1" ? true : false;
            dispatch(editCategory(category));
        }
        return res;
    });

// Admin Projects Actions
export const getProductsDispatch = (products) => ({
    type: ADMIN_GET_PRODUCTS,
    products,
});

export const editProduct = (product) => ({
    type: ADMIN_UPDATE_PRODUCT,
    product,
});

export const adminGetProducts = (page, search) => (dispatch) =>
    api.admin.getProducts(page, search).then((res) => {
        const products = res.products;
        if (products) {
            dispatch(getProductsDispatch(products));
        }
        return res;
    });

export const adminEditProduct = (data) => (dispatch) =>
    api.admin.adminEditProduct(data).then((res) => {
        const product = res.product;
        if (product) {
            product.status = product.status === "1" ? true : false;
            dispatch(editProduct(product));
        }
        return res;
    });

// Admin Settings
export const saveSettingsThunk = (settings) => ({
    type: ADMIN_SAVE_SETTINGS,
    settings,
});

export const saveSettings = (data) => (dispatch) =>
    api.admin.saveSettings(data).then((res) => {
        const settings = res.settings;
        if (settings) {
            dispatch(saveSettingsThunk(settings));
        }
        return res;
    });

export const getSettings = () => (dispatch) =>
    api.admin.getSettings().then((res) => {
        const settings = res.settings;
        if (settings) {
            dispatch(saveSettingsThunk(settings));
        }
        return res;
    });

export const importNow = () => (dispatch) =>
    api.admin.importNow().then((res) => {
        return res;
    });

// Notification Mails
export const updateMailsThunk = (mails) => ({
    type: ADMIN_SAVE_MAILS,
    mails,
});

export const updateMails = (data) => (dispatch) =>
    api.admin.updateMails(data).then((res) => {
        const mails = res.mails;
        dispatch(updateMailsThunk(mails));
        return res;
    });

export const getMails = (data) => (dispatch) =>
    api.admin.getMails(data).then((res) => {
        const mails = res.mails;
        dispatch(updateMailsThunk(mails));
        return res;
    });

// Get DashBoard products
export const getImageStatus = (product_id) => () => api.admin.getImageStatus(product_id);
export const homeGetImageStatus = (product_id) => () => api.home.getImageStatus(product_id);
export const updateImageStatus = (data) => () => api.admin.updateImageStatus(data);

